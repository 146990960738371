<template>
    <div class="report">
        <div class="form">
            <el-form
                ref="form"
                style="
                    flex: 1;
                    margin-top: 20px;
                    margin-left: 40px;
                    margin-bottom: 50px;
                    padding-bottom: 10px;
                "
                label-position="left"
                :model="form"
                label-width="100px"
            >
                <el-form-item label="报备品牌" required>
                    <span class="brand">腾讯企业邮箱</span><br />
                    <span class="brand" style="font-weight: 600; color: #333"
                        >最大报备名额：{{
                            account.maxReportNumber
                        }}
                        ，已报备：{{ account.consumedNumber }}</span
                    >
                </el-form-item>
                <el-form-item label="客户" required v-if="isType == 1">
                    <el-select
                        filterable
                        :remote-method="remoteMethod"
                        remote
                        v-model="value"
                        size="small"
                        style="width: 240px"
                        placeholder="请选择或搜索客户"
                        value-key="id"
                        @change="getCompanyData"
                        ref="searchSelect"
                    >
                        <el-option
                            v-for="item in options"
                            :key="item.id"
                            :label="item.companyName"
                            :value="item"
                        >
                        </el-option>
                    </el-select>
                    <el-button
                        type="primary"
                        style="
                            border-color: #2370eb;
                            background: #2370eb;
                            margin-left: 10px;
                        "
                        size="small"
                        @click="addCustomer"
                        >添加关联客户</el-button
                    >
                </el-form-item>

                <el-form-item
                    style="margin: 0; margin-top: -20px"
                    v-if="
                        (form.enterpriseType == 1 && aiChaStatus != 1) ||
                        (aiChaStatus != 1 &&
                            form.enterpriseType == 1 &&
                            radio != 1 &&
                            value != '')
                    "
                >
                    <span
                        style="
                            color: #666666;
                            font-size: 12px;
                            text-aligin: center;
                            line-height: 10px;
                        "
                    >
                        <i
                            class="iconfont icon-shangchuanshibaixian-copy"
                            style="color: #f5a623"
                        ></i>
                        未查询到工商局注册信息，请先修改客户名称
                    </span>
                </el-form-item>
                <el-form-item style="margin: 0; margin-top: -20px" v-else>
                    <span style="color: #666666; font-size: 12px">
                        <i
                            class="iconfont icon-shangchuanshibaixian-copy"
                            style="color: #f5a623"
                        ></i>
                        若客户名称包含括号，请使用中文输入法下的括号</span
                    >
                </el-form-item>
                <el-form-item label="企业类型" required>
                    <el-input
                        size="small"
                        :value="
                            form.enterpriseType == 1
                                ? '国内企业(不含港澳台)'
                                : form.enterpriseType == 2
                                ? '个人'
                                : form.enterpriseType == 3
                                ? '外资企业'
                                : ''
                        "
                        placeholder="请选择客户"
                        disabled
                    ></el-input>
                </el-form-item>
                <div
                    v-for="(item, index) in chanceCustomerDOList"
                    :key="index + 1"
                >
                    <el-form-item
                        label="关联客户"
                        style="position: relative"
                        class="domain_box"
                    >
                        <el-select
                            filterable
                            :remote-method="remoteMethod2"
                            remote
                            v-model="item.customer"
                            size="small"
                            style="width: 240px"
                            placeholder="请选择或搜索"
                            value-key="id"
                            @change="
                                (val) => {
                                    getCompanyData2(val, index);
                                }
                            "
                            ref="searchSelect"
                        >
                            <el-option
                                v-for="itm in options"
                                :key="itm.id"
                                :label="itm.companyName"
                                :value="itm"
                            >
                            </el-option>
                        </el-select>
                        <div class="domain_update_box">
                            <el-button
                                @click="delCustomer(index)"
                                class="el-icon-minus"
                                type="primary"
                                circle
                                size="mini"
                                style="background: #fff"
                            ></el-button>
                        </div>
                    </el-form-item>

                    <el-form-item
                        style="margin: 0; margin-top: -20px"
                        v-if="item.enterpriseType == 1 && item.aiChaStatus != 1"
                    >
                        <span
                            style="
                                color: #666666;
                                font-size: 12px;
                                text-aligin: center;
                                line-height: 10px;
                            "
                        >
                            <i
                                class="iconfont icon-shangchuanshibaixian-copy"
                                style="color: #f5a623"
                            ></i>
                            未查询到工商局注册信息，请先修改客户名称
                        </span>
                    </el-form-item>
                    <el-form-item
                        style="margin: 0; margin-top: -20px"
                        v-else-if="!item.tag"
                    >
                        <span
                            style="
                                color: #666666;
                                font-size: 12px;
                                text-aligin: center;
                                line-height: 10px;
                            "
                        >
                            <i
                                class="iconfont icon-shangchuanshibaixian-copy"
                                style="color: #f5a623"
                            ></i>
                            关联客户重复，请修改或删除
                        </span>
                    </el-form-item>
                </div>
                <el-form-item label="报备名称" required>
                    <el-input
                        v-model="form.reportName"
                        size="small"
                        placeholder="请选择客户"
                    ></el-input>
                </el-form-item>
                <el-form-item label="联系人" required>
                    <el-input
                        v-if="radio != 1 && value != ''"
                        style=""
                        v-model="form.contactName"
                        size="small"
                        placeholder="请输入联系人"
                        clearable=""
                    ></el-input>
                    <el-select
                        v-else
                        size="small"
                        style="width: 240px"
                        placeholder="请选择搜索或直接输入"
                        clearable
                        allow-create
                        filterable
                        remote
                        v-model="contactData"
                        value-key="contactName"
                        @change="getContactPhone()"
                    >
                        <el-option
                            v-for="item in contactList"
                            :key="item.index"
                            :label="item.contactName"
                            :value="item"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="联系电话" required>
                    <el-input
                        v-if="radio != 1 && value != ''"
                        v-model="form.contactPhone"
                        size="small"
                        placeholder="请输入联系电话"
                        clearable
                    ></el-input>
                    <el-select
                        v-else
                        size="small"
                        style="width: 240px"
                        placeholder="请选择搜索或直接输入"
                        clearable
                        allow-create
                        filterable
                        remote
                        v-model="contactPhoneData"
                        value-key="contactContent"
                        @change="getPhoneData()"
                    >
                        <el-option
                            v-for="item in phoneList"
                            :key="item.index"
                            :label="item.contactContent"
                            :value="item"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    :label="index == 0 ? '域名' : '关联域名'"
                    :required="index == 0"
                    style="position: relative"
                    v-for="(item, index) in chanceDomainDOList"
                    :key="index"
                    class="domain_box"
                >
                    <el-select
                        allow-create
                        filterable
                        :remote-method="
                            (val) => {
                                remoteMethod3(val, index);
                            }
                        "
                        remote
                        v-model="item.domain"
                        size="small"
                        style="width: 240px"
                        placeholder="请选择搜索或直接输入域名"
                        value-key="index"
                        :filter-method="
                            (val) => {
                                dataFilter3(val, index);
                            }
                        "
                        @change="
                            (val) => {
                                getCompanyData3(val, index);
                            }
                        "
                        ref="searchSelect"
                        @clear="setValueNull"
                    >
                        <el-option
                            v-for="(item, index) in domainList"
                            :key="index"
                            :label="item.domain"
                            :value="item.domain"
                        >
                        </el-option>
                    </el-select>

                    <p
                        style="color: #666666; font-size: 12px"
                        v-if="item.tag === false && item.domain"
                    >
                        <i
                            class="el-icon-warning-outline"
                            style="color: #f5a623"
                        ></i>
                        本域名重复无法报备，请修改或删除
                    </p>
                    <div
                        class="domain_rules_box"
                        v-if="
                            item.domain &&
                            (item.tag === false || item.tag === true)
                        "
                    >
                        <el-button
                            v-if="item.tag"
                            type="primary"
                            class="el-icon-success"
                            circle
                            size="mini"
                        ></el-button>
                        <el-button
                            v-else
                            class="el-icon-warning"
                            type="primary"
                            circle
                            size="mini"
                        ></el-button>
                    </div>
                    <div class="domain_update_box">
                        <el-button
                            v-if="index == 0"
                            @click="addDomain"
                            type="primary"
                            class="el-icon-plus"
                            style="background: #fff"
                            circle
                            size="mini"
                        ></el-button>
                        <el-button
                            v-if="index > 0"
                            @click="delDomain(index)"
                            class="el-icon-minus"
                            type="primary"
                            circle
                            size="mini"
                            style="background: #fff"
                        ></el-button>
                    </div>
                </el-form-item>
                <el-form-item label="所在地" required>
                    <el-cascader
                        size="small"
                        v-if="form.enterpriseType != 3"
                        placeholder="请选择所在地"
                        style="width: 240px"
                        clearable
                        v-model="addressArr"
                        :options="$address.cascader()"
                    ></el-cascader>
                    <el-input
                        size="small"
                        v-else
                        v-model="form.abroadArea"
                        placeholder="请输入所在地"
                        style="width: 240px"
                    ></el-input>
                </el-form-item>

                <el-form-item label="报备理由" required>
                    <el-input
                        type="textarea"
                        v-model="form.reportReason"
                        size="small"
                        placeholder="请输入报备理由"
                        style="width: 240px"
                        :rows="4"
                    ></el-input>
                </el-form-item>
                <el-form-item label="预计用户数" required>
                    <el-select
                        size="small"
                        v-model="form.estimateNumber"
                        placeholder="请选择预计用户数"
                        style="width: 240px"
                        clearable
                    >
                        <el-option
                            v-for="item in $MailStatus.estimateNumber()"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="预计年限" required>
                    <el-select
                        size="small"
                        v-model="form.estimateTerm"
                        placeholder="请选择预计年限"
                        style="width: 240px"
                        clearable
                    >
                        <el-option
                            v-for="item in $MailStatus.estimateTerm()"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    label="合作人"
                    style="position: relative"
                    class="domain_box"
                    v-for="(item, index) in chanceCollaboratorDOList"
                >
                    <el-select
                        v-model="item.collaboratorAdminId"
                        size="small"
                        style="width: 240px"
                        filterable
                        clearable
                        placeholder="请选择成员"
                        @change="
                            (val) => {
                                chnageCollaborator(val, index);
                            }
                        "
                    >
                        <el-option
                            v-for="item in userName"
                            :key="item.id"
                            :label="item.nickName"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                    <div class="domain_update_box">
                        <el-button
                            v-if="index == 0"
                            @click="addChanceCollaborator"
                            type="primary"
                            class="el-icon-plus"
                            style="background: #fff"
                            circle
                            size="mini"
                        ></el-button>
                        <el-button
                            v-if="index > 0"
                            @click="delChanceCollaborator(index)"
                            class="el-icon-minus"
                            type="primary"
                            circle
                            size="mini"
                            style="background: #fff"
                        ></el-button>
                    </div>
                </el-form-item>
                <el-form-item label="预计合作时间">
                    <el-date-picker
                        v-model="form.estimateCooperateTime"
                        size="small"
                        type="date"
                        placeholder="请选择预计合作时间"
                        value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="预计金额">
                    <el-input
                        v-model="form.estimateAmount"
                        size="small"
                        placeholder="请输入预计金额"
                        clearable
                        @input="
                            () => {
                                form.estimateAmount =
                                    form.estimateAmount.replace(/[^0-9]/g, '');
                            }
                        "
                    ></el-input>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input
                        type="textarea"
                        v-model="form.reportRemark"
                        size="small"
                        placeholder="请输入备注"
                        style="width: 240px"
                        :rows="4"
                    ></el-input>
                </el-form-item>
            </el-form>
        </div>
        <div class="bottom-but">
            <p>
                <el-button
                    round
                    type="primary"
                    size="mini"
                    style="
                        background: none;
                        border-color: #d5d6df;
                        color: #666666;
                    "
                    @click="onClose"
                    >取消</el-button
                >
                <el-button
                    round
                    type="primary"
                    style="
                        background: #2370eb;
                        border-color: #2370eb;
                        color: #fff;
                    "
                    size="mini"
                    @click="onSubmit(8)"
                    >申请报备</el-button
                >
            </p>
        </div>
    </div>
</template>
<script>
import { provinceAndCityData } from 'element-china-area-data';
import {
    reportAdd,
    customerList,
    reportList,
    accountGet,
} from '@/api/report/newReport';
import { checkDomain, accountList } from '@/api/report/report';
import { contactAdd } from '@/api/user/ae/customer';
import { addCustomer } from '@/api/user/ae/customer';
import {
    getCustomerId,
    getCustomerAdmin,
    customerAddMy,
} from '@/api/user/ae/customer';
import { nameGet, roleList } from '@/api/customer/customer.js';
import { channeManagelList } from '@/api/reportchannel/reportchannel.js';
export default {
    data() {
        return {
            form: {
                channelManageId: '',
                customerId: '',
                contactName: '',
                contactPhone: '',
                reportName: '',
                reportDomain: 'www.',
                reportReason: '',
                estimateNumber: '',
                estimateTerm: '',
                estimateCooperateTime: '',
                estimateAmount: '',
                reportRemark: '',
                abroadArea: '',

                enterpriseType: 1,
            },
            chanceDomainDOList: [{}],
            chanceCustomerDOList: [],
            enterpriseType: '',
            option: provinceAndCityData,
            addressArr: [],
            options: [],
            optionsFilter: [],
            contactList: [],
            value: {},
            contactData: {},
            chanceCollaboratorDOList: [{}],
            phoneList: [],
            contactPhoneData: {},
            radio: 1,
            isAddContact: false,
            isDomain: false,
            isName: false,
            account: {},
            checkDomainText: '',
            isDomainInp: false,
            domainList: [],
            tag1: '',
            enterpriseType: '',
            aiChaStatus: 1,
            companyList: [],
            tableRadio: {},
            isType: 0,
            libraryCategory: 0,
            channelListData: [],
            userName: [],
        };
    },
    mounted() {},
    methods: {
        // 获取部门成员
        getUserList() {
            let data = {
                param: {
                    configAdminId: sessionStorage.getItem('adminId')
                },
            };
            roleList(data).then((res) => {
                this.userName = res.data;
            });
        },
        accountList() {
            let data = {
                param: {
                    productType: 5,
                },
            };
            accountList(data).then((res) => {
                if (res.code == 200) {
                    this.libraryCategory = res.data.list[0].id;
                }
            });
        },
        remoteMethod(query) {
            this.getData(query);
        },
        remoteMethod2(query) {
            let data = {
                param: {
                    adminId: sessionStorage.getItem('adminId'),
                    enterpriseTypeList: [1, 3],
                },
                pageNum: 1,
                pageSize: 20,
            };
            if (query) {
                data.param.name = query;
            }
            getCustomerAdmin(data).then((res) => {
                this.options = res.data.list;
                this.optionsFilter = res.data.list;
            });
        },
        getisType(i) {
            this.isType = i;
            let data = {
                param: {
                    status: 1,
                },
                pageNum: 0,
                pageSize: 0,
            };
            channeManagelList(data).then((res) => {
                if (res.code == 200) {
                    this.channelListData = res.data.list;
                }
            });
        },
        getData(name) {
            for (let i in this.form) {
                this.form[i] = '';
            }
            this.contactData = {};
            this.contactList = [];
            this.phoneList = [];
            this.contactPhoneData = {};
            this.value = '';
            this.addressArr = [];
            this.adminIdList = [{}];
            sessionStorage.setItem('customerlist', 1);
            this.isAddContact = false;

            this.form.enterpriseType = '';
            let data = {
                param: {
                    adminId: sessionStorage.getItem('adminId'),
                    enterpriseTypeList: [1, 3],
                },
                pageNum: 1,
                pageSize: 20,
            };
            if (name) {
                data.param.name = name;
            }
            getCustomerAdmin(data).then((res) => {
                this.options = res.data.list;
                this.optionsFilter = res.data.list;
            });
            let obj = {
                param: {
                    productType: 5,
                    adminId: sessionStorage.getItem('adminId'),
                },
            };
            accountGet(obj).then((res) => {
                this.account = res.data;
            });
            this.getUserList();
        },
        dataFilter(val) {
            this.value = val;
            if (val) {
                this.options = this.optionsFilter.filter((item) => {
                    if (
                        item.companyName.includes(val) ||
                        item.companyName
                            .toUpperCase()
                            .includes(val.toUpperCase())
                    ) {
                        return true;
                    }
                });
            } else {
                this.options = this.optionsFilter;
            }
        },

        setValueNull() {
            this.getData();
        },
        getCompanyData2(val, index) {
            this.returnRepeat(val, index);
            let data = {
                param: {
                    id: val.id,
                },
            };
            getCustomerId(data).then((res) => {
                console.log(res);
                let obj = {
                    param: {
                        companyName: val.companyName,
                    },
                };
                nameGet(obj).then((res2) => {
                    if (res.code == 200) {
                        this.chanceCustomerDOList[index].aiChaStatus =
                            res2.data.aiChaStatus;
                        this.chanceCustomerDOList[index].enterpriseType =
                            res.data.enterpriseType;
                        this.chanceCustomerDOList = JSON.parse(
                            JSON.stringify(this.chanceCustomerDOList)
                        );
                    }
                });
            });
        },
        getCompanyData(e) {
            if (!this.contactData.id) {
                this.contactData = '';
            } else {
                this.contactData = {};
            }
            if (!this.contactPhoneData.contactId) {
                this.contactPhoneData = '';
            } else {
                this.contactPhoneData = {};
            }
            this.addressArr = [];
            for (let i in this.form) {
                this.form[i] = '';
            }
            this.tag1 = '';
            if (this.value.constructor === Object) {
                this.form.reportName = this.value.companyName;
                this.contactList = [];
                this.radio = 1;
                this.form.customerId = this.value.id;
                this.form.companyName = this.value.companyName;
                this.form.reportDomain = this.value.companyDomain;
                this.form.reportName = this.value.companyName;
                this.form.contactPhone;
                this.form.term = this.value.term;
                this.form.number = this.value.number;
                this.addressArr = [];
                this.isDomainInp = true;
                this.domainList = [];
                this.chanceDomainDOList = [{}];
                this.chanceCustomerDOList = [];
                this.chanceCollaboratorDOList = [{}];
                let data = {
                    param: {
                        id: this.value.id,
                    },
                };
                getCustomerId(data).then((res) => {
                    console.log(res.data);
                    this.enterpriseType = res.data.enterpriseType;
                    this.form.enterpriseType = res.data.enterpriseType;

                    if (res.data.companyDomain) {
                        this.domainList.push({
                            domain: res.data.companyDomain,
                            tag: true,
                        });
                    }
                    if (res.data.customerDomainList.length > 0) {
                        for (
                            let i = 0;
                            i < res.data.customerDomainList.length;
                            i++
                        )
                            this.domainList.push({
                                domain: res.data.customerDomainList[i].domain,
                                tag: true,
                            });
                    }
                });

                if (this.value.provinceId) {
                    this.addressArr.push(
                        String(this.value.provinceId),
                        String(this.value.cityId)
                    );
                }
                this.contactList = this.value.customerContactList;

                this.nameGet(this.value.companyName);
                this.onCheckName();
            } else {
                this.isDomainInp = false;
                this.radio = 2;
                this.form.reportName = this.value;
                this.form.enterpriseType = 1;

                this.nameGet(this.value);
                this.onCheckName();
            }
        },
        nameGet(e) {
            let data = {
                param: {
                    companyName: e,
                },
            };
            nameGet(data).then((res) => {
                if (res.code == 200) {
                    this.aiChaStatus = res.data.aiChaStatus;
                }
            });
        },
        // 获取联系人电话
        getContactPhone() {
            console.log(this.contactPhoneData);
            if (!this.contactData.id) {
                this.isAddContact = true;
            }
            if (!this.contactPhoneData.contactId) {
                this.contactPhoneData = '';
            } else {
                this.contactPhoneData = {};
            }

            this.phoneList = [];
            for (let i in this.contactData.customerContactWayDOList) {
                if (
                    this.contactData.customerContactWayDOList[i].contactWay == 1
                ) {
                    this.phoneList.push(
                        this.contactData.customerContactWayDOList[i]
                    );
                }
            }
        },
        getPhoneData() {
            if (!this.contactPhoneData.contactId) {
                this.isAddContact = true;
            }
        },
        addDomain() {
            this.chanceDomainDOList.push({});
        },
        delDomain(index) {
            this.chanceDomainDOList.splice(index, 1);
        },
        addChanceCollaborator() {
            if (this.chanceCollaboratorDOList.length == 5) {
                return this.$message.error('合作人最多添加5个');
            }
            this.chanceCollaboratorDOList.push({
                collaboratorAdminId: '',
            });
        },
        delChanceCollaborator(index) {
            this.chanceCollaboratorDOList.splice(index, 1);
        },
        chnageCollaborator(val, index) {
            if (
                this.chanceCollaboratorDOList.some((item, idx) => {
                    return item.collaboratorAdminId == val && index != idx;
                })
            ) {
                this.chanceCollaboratorDOList[index].collaboratorAdminId = '';
                return this.$message.error('合作人重复，请重新选择');
            }
        },
        addCustomer() {
            if (this.chanceCustomerDOList.length == 3) {
                return this.$message.error('最多添加3个关联客户');
            }
            this.chanceCustomerDOList.push({
                tag: true,
            });
        },
        delCustomer(index) {
            this.chanceCustomerDOList.splice(index, 1);
        },
        dataFilter3(val, index) {
            this.chanceDomainDOList[index].domain = val;
        },
        getCompanyData3(val, index) {
            console.log(123);
            if (val.indexOf('www.') == -1) {
                val = 'www.' + val;
            }
            this.domainInput(val, index);
        },
        remoteMethod3(query, index) {
            if (!/^www/.test(query)) {
                query = 'www.' + query;
            }
            this.domainInput(query, index);
        },
        // 报备名称校验
        onCheckName(query) {
            if (query) {
                this.form.reportName = query;
            }
            this.isName = false;
            if (this.form.reportName) {
                let data = {
                    param: {
                        reportName: this.form.reportName,
                        productType: 5,
                        reportStatusList: [3, 8, 9],
                    },
                };
                reportList(data).then((res) => {
                    if (!res.data.list || res.data.list.length == 0) {
                        this.isName = true;
                    } else {
                        this.isName = true;
                        for (let i in res.data.list) {
                            if (
                                res.data.list[i].adminId !=
                                sessionStorage.getItem('adminId')
                            ) {
                                return (this.isName = false);
                            }
                        }
                    }
                });
            }
        },
        copy() {
            let url = this.form.reportDomain;
            let oInput = document.createElement('input');
            oInput.value = url;
            document.body.appendChild(oInput);
            oInput.select(); // 选择对象;
            document.execCommand('Copy'); // 执行浏览器复制命令
            this.$message({
                message: '复制成功',
                type: 'success',
            });
            oInput.remove();
        },
        // 客户和关联客户重复校验
        returnRepeat(val, index) {
            console.log(val);
            if (val.id == this.value.id) {
                this.chanceCustomerDOList[index].tag = false;
                this.chanceCustomerDOList = JSON.parse(
                    JSON.stringify(this.chanceCustomerDOList)
                );
                return;
            }
            if (
                this.chanceCustomerDOList.some((item, idx) => {
                    if (idx != index) {
                        if (item.customer.id && item.customer.id == val.id) {
                            return true;
                        }
                    }
                })
            ) {
                this.chanceCustomerDOList[index].tag = false;
                this.chanceCustomerDOList = JSON.parse(
                    JSON.stringify(this.chanceCustomerDOList)
                );
                return;
            }
        },
        // 域名重复校验
        domainInput(val, index) {
            this.chanceDomainDOList[index].domain = val;
            this.chanceDomainDOList = JSON.parse(
                JSON.stringify(this.chanceDomainDOList)
            );

            if (
                this.chanceDomainDOList.some((item, idx) => {
                    if (idx != index) {
                        if (item.domain && item.domain == val) {
                            return true;
                        }
                    }
                })
            ) {
                this.chanceDomainDOList[index].tag = false;
                return;
            }
            if (index > 0) {
                return;
            }
            let data = {
                param: {
                    productType: 5,
                    domainList: [val],
                },
            };
            checkDomain(data).then((res) => {
                if (res.code != 200) {
                    this.chanceDomainDOList[index].tag = false;
                } else {
                    this.chanceDomainDOList[index].tag = true;
                }
                this.chanceDomainDOList = JSON.parse(
                    JSON.stringify(this.chanceDomainDOList)
                );
            });
        },
        onSubmit(i) {
            if (this.account.maxReportNumber < this.account.consumedNumber) {
                return this.$message.error('报备名额不足，不允许报备');
            }

            if (this.isType == 1) {
                if (this.form.enterpriseType != 3) {
                    this.form.provinceId = Number(this.addressArr[0]) || 0;
                    this.form.cityId = Number(this.addressArr[1]) || 0;
                }
                // console.log(
                //     this.enterpriseType,
                //     this.aiChaStatus,
                //
                // );

                if (
                    (this.enterpriseType == 1 && this.aiChaStatus != 1) ||
                    (this.aiChaStatus != 1 && this.form.enterpriseType == 1)
                ) {
                    if (i == 8) {
                        return this.$message.error(
                            '客户名称有误，无法对客户进行报备'
                        );
                    }
                }

                if (!this.value) {
                    return this.$message.error('请选择或输入客户');
                } else if (this.radio != 1 && !this.form.enterpriseType) {
                    return this.$message.error('请选择企业类型');
                } else if (!this.form.contactName && !this.contactData) {
                    return this.$message.error('请选择或输入联系人');
                } else if (!this.form.contactPhone && !this.contactPhoneData) {
                    return this.$message.error('请选择或输入联系手机号');
                } else if (!this.form.reportName) {
                    return this.$message.error('请输入报备名称');
                }
                // else if (!this.isName) {
                //     return this.$message.error('当前报备名称已报备，申请失败');
                // }
                //  else if (
                //     this.form.reportDomain &&
                //     this.form.reportDomain.indexOf('www') == -1
                // ) {
                //     return this.$message.error('域名必须为WWW开头');
                // }
                // else if (!this.isDomain) {
                //     return this.$message.error('域名不能重复报备');
                // }
                else if (
                    this.form.enterpriseType != 3 &&
                    !this.form.provinceId
                ) {
                    return this.$message.error('请选择所在地');
                } else if (
                    this.form.enterpriseType == 3 &&
                    !this.form.abroadArea
                ) {
                    return this.$message.error('请输入所在地');
                } else if (!this.form.reportReason) {
                    return this.$message.error('请输入报备理由');
                } else if (!this.form.estimateNumber) {
                    return this.$message.error('请选择预计用户数');
                } else if (!this.form.estimateTerm) {
                    return this.$message.error('请选择预计年限');
                } else {
                    for (let i in this.chanceDomainDOList) {
                        if (!this.chanceDomainDOList[i].domain) {
                            return this.$message.error('请输入域名');
                        }
                        if (this.chanceDomainDOList[i].tag === false) {
                            return this.$message.error('域名不能重复');
                        }
                        if (!/^www/.test(this.chanceDomainDOList[i].domain)) {
                            return this.$message.error('域名必须已www开头');
                        }
                    }

                    console.log(this.radio);
                    if (this.radio == 1) {
                        let obj = {
                            param: {
                                customerId: this.value.id,
                                contactName: this.contactData.contactName
                                    ? this.contactData.contactName
                                    : this.contactData,
                                contactPhone: this.contactPhoneData
                                    .contactContent
                                    ? this.contactPhoneData.contactContent
                                    : this.contactPhoneData,
                                reportName: this.Turn(this.form.reportName),
                                reportReason: this.form.reportReason,
                                estimateNumber: this.form.estimateNumber,
                                estimateTerm: this.form.estimateTerm,
                                estimateCooperateTime:
                                    this.form.estimateCooperateTime,
                                estimateCooperateTime:
                                    this.form.estimateCooperateTime,
                                reportRemark: this.form.reportRemark,
                                provinceId: this.addressArr[0],
                                cityId: this.addressArr[1],
                                estimateAmount: this.form.estimateAmount,
                                abroadArea: this.form.abroadArea,

                                nicheCategory: 1,
                                libraryCategory: this.libraryCategory,
                                chanceCollaboratorDOList:
                                    this.chanceCollaboratorDOList.filter(
                                        (item) => {
                                            return item.collaboratorAdminId;
                                        }
                                    ),
                            },
                        };

                        obj.param.reportDomain =
                            this.chanceDomainDOList[0].domain;

                        obj.param.chanceDomainDOList =
                            this.chanceDomainDOList.map((item) => {
                                return {
                                    domain: item.domain,
                                };
                            });
                        obj.param.chanceDomainDOList =
                            obj.param.chanceDomainDOList.filter(
                                (val, index, arr) => {
                                    return index !== 0;
                                }
                            );
                        obj.param.chanceCustomerDOList =
                            this.chanceCustomerDOList.map((item) => {
                                return {
                                    customerId: item.customer.id,
                                };
                            });
                        console.log(obj);
                        if (this.isAddContact) {
                            let data = {
                                param: {
                                    customerContactDO: {},
                                },
                            };
                            console.log(this.tableRadio.id, this.value.id);
                            if (this.tableRadio.id) {
                                data.param.customerContactDO = {
                                    companyName: this.tableRadio.companyName,
                                    contactName: this.contactData.contactName,
                                    customerContactWayDOList: [
                                        {
                                            contactContent: this.value2,
                                            contactWay: 1,
                                        },
                                    ],
                                };
                                data.param.customerContactDO.customerId =
                                    this.tableRadio.id;
                            } else {
                                data.param.customerContactDO = {
                                    companyName: this.value.companyName,
                                    contactName: this.contactData.contactName
                                        ? this.contactData.contactName
                                        : this.contactData,
                                    customerContactWayDOList: [
                                        {
                                            contactContent: this
                                                .contactPhoneData.contactContent
                                                ? this.contactPhoneData
                                                      .contactContent
                                                : this.contactPhoneData,
                                            contactWay: 1,
                                        },
                                    ],
                                };
                                data.param.customerContactDO.followAdminId =
                                    sessionStorage.getItem('adminId');
                                data.param.customerContactDO.customerId =
                                    this.value.id;
                            }
                            data.param.customerContactDO.followAdminId =
                                sessionStorage.getItem('adminId');
                            contactAdd(data).then((res) => {});
                        }
                        this.submit(obj);
                    }
                }
            } else {
                if (this.form.enterpriseType != 3) {
                    this.form.provinceId = Number(this.addressArr[0]) || 0;
                    this.form.cityId = Number(this.addressArr[1]) || 0;
                }
                if (!this.form.channelManageId) {
                    return this.$message.error('请选择报备渠道');
                } else if (!this.form.contactName) {
                    return this.$message.error('请输入联系人');
                } else if (!this.form.contactPhone) {
                    return this.$message.error('请输入联系手机号');
                } else if (!this.form.reportName) {
                    return this.$message.error('请输入报备名称');
                }
                //  else if (!this.isName) {
                //     return this.$message.error('当前报备名称已报备，申请失败');
                // }
                // else if (!this.form.reportDomain) {
                //     return this.$message.error('请输入域名');
                // } else if (
                //     this.form.reportDomain &&
                //     this.form.reportDomain.indexOf('www') == -1
                // ) {
                //     return this.$message.error('域名必须为WWW开头');
                // }
                //  else if (!this.isDomain) {
                //     return this.$message.error('域名不能重复报备');
                // }
                else if (
                    this.form.enterpriseType != 3 &&
                    !this.form.provinceId
                ) {
                    return this.$message.error('请选择所在地');
                } else if (
                    this.form.enterpriseType == 3 &&
                    !this.form.abroadArea
                ) {
                    return this.$message.error('请输入所在地');
                } else if (!this.form.reportReason) {
                    return this.$message.error('请输入报备理由');
                } else if (!this.form.estimateNumber) {
                    return this.$message.error('请选择预计用户数');
                } else if (!this.form.estimateTerm) {
                    return this.$message.error('请选择预计年限');
                } else {
                    let obj = {
                        param: {
                            channelManageId: this.form.channelManageId,
                            contactName: this.form.contactName,
                            contactPhone: this.form.contactPhone,
                            reportName: this.Turn(this.form.reportName),

                            reportReason: this.form.reportReason,
                            estimateNumber: this.form.estimateNumber,
                            estimateTerm: this.form.estimateTerm,
                            estimateCooperateTime:
                                this.form.estimateCooperateTime,
                            estimateCooperateTime:
                                this.form.estimateCooperateTime,
                            reportRemark: this.form.reportRemark,
                            provinceId: this.addressArr[0],
                            cityId: this.addressArr[1],
                            estimateAmount: this.form.estimateAmount,

                            abroadArea: this.form.abroadArea,

                            nicheCategory: 2,
                            libraryCategory: this.libraryCategory,
                           chanceCollaboratorDOList:
                                    this.chanceCollaboratorDOList.filter(
                                        (item) => {
                                            return item.collaboratorAdminId;
                                        }
                                    ),
                        },
                    };
                    obj.param.reportDomain = this.chanceDomainDOList[0].domain;

                    obj.param.chanceDomainDOList = this.chanceDomainDOList.map(
                        (item) => {
                            return {
                                domain: item.domain,
                            };
                        }
                    );
                    obj.param.chanceDomainDOList =
                        obj.param.chanceDomainDOList.filter(
                            (val, index, arr) => {
                                return index !== 0;
                            }
                        );
                    obj.param.chanceCustomerDOList =
                        this.chanceCustomerDOList.map((item) => {
                            return {
                                customerId: item.customer.id,
                            };
                        });

                    this.submit(obj);
                }
            }
        },
        submit(obj) {
            obj.param.productType = 5;

            reportAdd(obj).then((res) => {
                if (res.code == 200) {
                    this.$message.success('申请成功');
                    if (obj.param.reportStatus == 6) {
                        this.$emit('close', 2);
                        for (let i in this.form) {
                            this.form[i] = '';
                        }
                        this.contactData = {};
                        this.contactList = [];
                        this.phoneList = [];
                        this.contactPhoneData = {};
                        this.chanceDomainDOList = [{}];
                        this.chanceCustomerDOList = [];
                        this.value = '';
                        this.addressArr = [];
                    } else if (obj.param.reportStatus == 8) {
                        this.$emit('close', 1);
                        for (let i in this.form) {
                            this.form[i] = '';
                        }
                        this.chanceDomainDOList = [{}];
                        this.chanceCustomerDOList = [];
                        this.contactData = {};
                        this.contactList = [];
                        this.phoneList = [];
                        this.contactPhoneData = {};
                        this.value = '';
                        this.addressArr = [];
                    } else {
                        this.onClose();
                    }
                }
            });
        },
        Turn(srt) {
            var reg = /[\(]/g,
                reg2 = /[\)]/g;
            return srt.replace(reg, '（').replace(reg2, '）');
        },
        onClose() {
            for (let i in this.form) {
                this.form[i] = '';
            }
            this.contactData = {};
            this.contactList = [];
            this.phoneList = [];
            this.contactPhoneData = {};
            this.value = '';
            this.addressArr = [];
            sessionStorage.removeItem('customerlist');
            this.$emit('close');
        },
    },
};
</script>
<style lang="scss" scoped>
.companyList {
    border: 1px solid #e5e5e5;
    margin-bottom: 10px;
    border-radius: 5px;
    overflow: hidden;
    button {
        background: #2370eb;
        border-radius: 13px;
        color: #ffffff;
        margin: 12px;
        margin-left: 40px;
    }
}
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.report {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    .form {
        flex: 1;
        overflow: auto;
    }
    .el-input {
        width: 240px;
    }
    .el-button--mini.is-circle {
        padding: 2px;
        background: #fff;
        color: #2370eb;
        font-weight: 600;
        border: 2px solid #2370eb;
        margin-left: 17px;
    }

    .bottom-but {
        width: 100%;
        line-height: 48px;
        background: #fff;
        border-top: 1px solid #dee0e3;
        z-index: 1000;
        bottom: 0;
        display: flex;
        p {
            margin-left: auto;
            margin-right: 20px;
        }
        button {
            width: 90px;
            z-index: 1000;
        }
    }
    .brand {
        display: inline-block;
        width: 260px;
        padding-left: 10px;
        color: #333333;
        font-size: 14px;
        border-radius: 2px;
    }
}
.domain_rules_box {
    position: absolute;
    right: 110px;
    top: 2px;
    .el-button--mini.is-circle {
        padding: 2px;
        width: 20px;
        height: 20px;
        background-color: transparent;
        border: none;
        margin-left: 0;
        font-size: 16px;
        font-weight: 600;
    }
    .el-icon-success {
        color: #46a136 !important;
    }
    .el-icon-warning {
        color: #f5a623 !important;
    }
}
.domain_rules_box2 {
    position: absolute;
    right: 23px;
    top: 0px;
    span {
        font-size: 12px;
        color: #f5a623;
    }
    .el-button--mini.is-circle {
        padding: 2px;
        width: 20px;
        height: 20px;
        background-color: transparent;
        border: none;
        margin-left: 0;
        font-size: 13px;
        font-weight: 600;
    }
    .el-button--mini.is-circle.el-icon-success {
        color: #46a136 !important;
        margin-left: -40px;
        font-size: 16px;
        top: 2px;
    }
    .el-icon-warning {
        color: #f5a623 !important;
    }
}

.domain_box {
    /deep/ .el-input__inner {
        padding-right: 20px !important;
    }
    .el-button--mini.is-circle {
        padding: 2px;
        background: #fff;
        color: #2370eb;
        font-weight: 600;
        width: 20px;
        height: 20px;
        border: 2px solid #2370eb;
    }

    .domain_update_box {
        position: absolute;
        right: 85px;
        top: 0px;
    }
}
.domain_rules_box {
    position: absolute;
    right: 110px;
    top: 2px;
    .el-button--mini.is-circle {
        padding: 2px;
        width: 20px;
        height: 20px;
        background-color: transparent;
        border: none;
        margin-left: 0;
        font-size: 16px;
        font-weight: 600;
    }
    .el-icon-success {
        color: #46a136 !important;
    }
    .el-icon-warning {
        color: #f5a623 !important;
    }
}
.domain_rules_box2 {
    position: absolute;
    right: 60px;
    top: 0px;
    span {
        font-size: 12px;
        color: #f5a623;
    }
    .el-button--mini.is-circle {
        padding: 2px;
        width: 20px;
        height: 20px;
        background-color: transparent;
        border: none;
        margin-left: 0;
        font-size: 13px;
        font-weight: 600;
    }
    .el-button--mini.is-circle.el-icon-success {
        color: #46a136 !important;
        margin-left: -40px;
        font-size: 16px;
        top: 2px;
    }
    .el-icon-warning {
        color: #f5a623 !important;
    }
}
</style>
<style>
.report .el-form-item {
    margin-bottom: 10px !important;
}
</style>
