var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"trial"},[_c('el-form',{ref:"form",staticStyle:{"flex":"1","margin-top":"20px","margin-left":"40px"},attrs:{"label-position":"left","model":_vm.form,"label-width":"100px"}},[_c('el-form-item',{staticStyle:{"margin-left":"-10px"},attrs:{"label":"报备品牌","required":""}},[_c('span',{staticClass:"brand"},[_vm._v("腾讯企业邮箱")])]),_c('el-form-item',{staticStyle:{"margin-left":"-10px"},attrs:{"label":"报备名称","required":""}},[_c('span',{staticClass:"brand"},[_vm._v(_vm._s(_vm.form.reportName))])]),(_vm.form.nicheCategory == 1)?_c('el-form-item',{staticStyle:{"margin-left":"-10px"},attrs:{"label":"客户","required":""}},[_c('span',{staticClass:"brand"},[_vm._v(_vm._s(_vm.form.companyName))])]):_vm._e(),(_vm.form.nicheCategory == 2)?_c('el-form-item',{staticStyle:{"margin-left":"-10px"},attrs:{"label":"报备渠道","required":""}},[_c('span',{staticClass:"brand"},[_vm._v(_vm._s(_vm.form.channelManageName))])]):_vm._e(),_c('el-form-item',{staticStyle:{"margin-left":"-10px"},attrs:{"label":"用户数","required":""}},[_c('el-input',{staticStyle:{"margin-left":"10px"},attrs:{"size":"small","placeholder":"请输入用户数","clearable":""},on:{"input":function () {
                        _vm.form.trialNumber = _vm.form.trialNumber.replace(/[^0-9]/g, '');
                    }},model:{value:(_vm.form.trialNumber),callback:function ($$v) {_vm.$set(_vm.form, "trialNumber", $$v)},expression:"form.trialNumber"}})],1),_c('el-form-item',{staticStyle:{"margin-left":"-10px"},attrs:{"label":"试用天数","required":""}},[_c('el-input',{staticStyle:{"margin-left":"10px"},attrs:{"size":"small","placeholder":"请输入试用天数","clearable":""},on:{"input":function () {
                        _vm.form.trialDays = _vm.form.trialDays.replace(/[^0-9]/g, '');
                    }},model:{value:(_vm.form.trialDays),callback:function ($$v) {_vm.$set(_vm.form, "trialDays", $$v)},expression:"form.trialDays"}})],1),_c('el-form-item',{staticStyle:{"margin-left":"-10px"},attrs:{"label":"邮箱版本","required":""}},[_c('el-select',{staticStyle:{"width":"270px","margin-left":"10px"},attrs:{"size":"small","placeholder":"请选择邮箱版本","clearable":""},on:{"change":function($event){_vm.form.emailVersionTypeName = ''}},model:{value:(_vm.form.emailVersionType),callback:function ($$v) {_vm.$set(_vm.form, "emailVersionType", $$v)},expression:"form.emailVersionType"}},[_c('el-option',{attrs:{"label":"旧版","value":3}}),_c('el-option',{attrs:{"label":"新版","value":2}})],1)],1),_c('el-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.emailVersionType != ''),expression:"form.emailVersionType != ''"}],staticStyle:{"margin-left":"-10px"},attrs:{"required":"","label":_vm.form.emailVersionType == 3
                    ? '管理员账号'
                    : _vm.form.emailVersionType == 2
                    ? '企业ID'
                    : ''}},[_c('el-input',{staticStyle:{"margin-left":"10px"},attrs:{"size":"small","clearable":"","placeholder":_vm.form.emailVersionType == 3
                        ? '请输入管理员账号'
                        : _vm.form.emailVersionType == 2
                        ? '请输入企业ID'
                        : ''},model:{value:(_vm.form.emailVersionTypeName),callback:function ($$v) {_vm.$set(_vm.form, "emailVersionTypeName", $$v)},expression:"form.emailVersionTypeName"}})],1),_c('el-form-item',{attrs:{"label":"备注"}},[_c('el-input',{staticStyle:{"width":"270px"},attrs:{"type":"textarea","size":"small","placeholder":"请输入备注"},model:{value:(_vm.form.applyRemark),callback:function ($$v) {_vm.$set(_vm.form, "applyRemark", $$v)},expression:"form.applyRemark"}})],1)],1),_c('div',{staticClass:"bottom-but"},[_c('el-button',{staticStyle:{"background":"none","border-color":"#d5d6df","color":"#666666","margin-left":"270px"},attrs:{"round":"","type":"primary","size":"mini"},on:{"click":_vm.onClose}},[_vm._v("取消")]),_c('el-button',{staticStyle:{"background":"#2370eb","border-color":"#2370eb","color":"#fff"},attrs:{"round":"","type":"primary","size":"mini"},on:{"click":_vm.onSubmit}},[_vm._v("申请试用")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }