<template>
    <div class="report">
        <div class="form">
            <el-form
                ref="form"
                style="
                    flex: 1;
                    margin-top: 20px;
                    margin-left: 40px;
                    margin-bottom: 50px;
                "
                label-position="left"
                :model="form"
                label-width="100px"
            >
                <el-form-item label="报备品牌" required>
                    <span class="brand">腾讯企业邮箱</span><br />
                    <span class="brand" style="font-weight: 600; color: #333"
                        >最大报备名额：{{
                            account.maxReportNumber
                        }}
                        ，已报备：{{ account.consumedNumber }}</span
                    >
                </el-form-item>

                <el-form-item label="客户" required>
                    <el-input v-model="form.companyName" disabled> </el-input>
                </el-form-item>
                <el-form-item
                    label="关联客户"
                    v-for="item in form.chanceCustomerBOList"
                    :key="item.id"
                >
                    <el-input v-model="item.companyName" disabled> </el-input>
                </el-form-item>
                <el-form-item label="企业类型" required>
                    <el-input
                        size="small"
                        :value="
                            enterpriseType == 1
                                ? '国内企业(不含港澳台)'
                                : enterpriseType == 2
                                ? '个人'
                                : enterpriseType == 3
                                ? '外资企业'
                                : ''
                        "
                        disabled
                        placeholder="请选择客户"
                    ></el-input>
                </el-form-item>
                <el-form-item label="联系人" required>
                    <el-select
                        size="small"
                        style="width: 270px"
                        placeholder="请选择搜索或直接输入"
                        clearable
                        allow-create
                        filterable
                        remote
                        v-model="contactData"
                        value-key="contactName"
                        @change="getContactPhone()"
                    >
                        <el-option
                            v-for="item in contactList"
                            :key="item.index"
                            :label="item.contactName"
                            :value="item"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="联系电话" required>
                    <el-select
                        size="small"
                        style="width: 270px"
                        placeholder="请选择搜索或直接输入"
                        clearable
                        allow-create
                        filterable
                        remote
                        v-model="contactPhoneData"
                        value-key="contactContent"
                        @change="getPhoneData()"
                    >
                        <el-option
                            v-for="item in phoneList"
                            :key="item.index"
                            :label="item.contactContent"
                            :value="item"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="报备名称" required>
                    <el-input
                        v-model="form.reportName"
                        size="small"
                        placeholder="请输入报备企业名称"
                        disabled
                    ></el-input>
                </el-form-item>
                <el-form-item label="域名" required>
                    <el-input
                        style
                        v-model="form.reportDomain"
                        size="small"
                        disabled
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="关联域名"
                    v-for="item in form.chanceDomainBOList"
                    :key="item.id"
                >
                    <el-input
                        style
                        v-model="item.domain"
                        size="small"
                        disabled
                    ></el-input>
                </el-form-item>

                <el-form-item label="所在地" required>
                    <el-cascader
                        size="small"
                        v-if="form.enterpriseType != 3"
                        placeholder="请选择所在地"
                        style="width: 270px"
                        clearable
                        v-model="addressArr"
                        :options="$address.cascader()"
                    ></el-cascader>
                    <el-input
                        size="small"
                        v-if="form.enterpriseType == 3"
                        v-model="form.abroadArea"
                        placeholder="请输入所在地"
                        style="width: 270px"
                    ></el-input>
                </el-form-item>
                <el-form-item label="报备理由" required>
                    <el-input
                        type="textarea"
                        v-model="form.reportReason"
                        size="small"
                        placeholder="请输入报备理由"
                        style="width: 270px"
                        :rows="4"
                    ></el-input>
                </el-form-item>
                <el-form-item label="预计用户数" required>
                    <el-select
                        size="small"
                        v-model="form.estimateNumber"
                        placeholder="请选择预计用户数"
                        style="width: 270px"
                        clearable
                    >
                        <el-option
                            v-for="item in $MailStatus.estimateNumber()"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="预计年限" required>
                    <el-select
                        size="small"
                        v-model="form.estimateTerm"
                        placeholder="请选择预计年限"
                        style="width: 270px"
                        clearable
                    >
                        <el-option
                            v-for="item in $MailStatus.estimateTerm()"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    label="合作人"
                    style="position: relative"
                    class="domain_box"
                    v-for="(item, index) in chanceCollaboratorDOList"
                >
                    <el-select
                        v-model="item.collaboratorAdminId"
                        size="small"
                        style="width: 270px"
                        filterable
                        clearable
                        placeholder="请选择成员"
                    >
                        <el-option
                            v-for="item in userName"
                            :key="item.id"
                            :label="item.nickName"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                    <div class="domain_update_box">
                        <el-button
                            v-if="index == 0"
                            @click="addChanceCollaborator"
                            type="primary"
                            class="el-icon-plus"
                            style="background: #fff"
                            circle
                            size="mini"
                        ></el-button>
                        <el-button
                            v-if="index > 0"
                            @click="delChanceCollaborator(index)"
                            class="el-icon-minus"
                            type="primary"
                            circle
                            size="mini"
                            style="background: #fff"
                        ></el-button>
                    </div>
                </el-form-item>
                <el-form-item label="预计合作时间">
                    <el-date-picker
                        v-model="form.estimateCooperateTime"
                        size="small"
                        type="date"
                        placeholder="请选择预计合作时间"
                        value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="预计金额">
                    <el-input
                        v-model="form.estimateAmount"
                        size="small"
                        placeholder="请输入预计金额"
                        clearable
                        @input="
                            () => {
                                form.estimateAmount =
                                    form.estimateAmount.replace(/[^0-9]/g, '');
                            }
                        "
                    ></el-input>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input
                        type="textarea"
                        v-model="form.reportRemark"
                        size="small"
                        placeholder="请输入备注"
                        style="width: 270px"
                        :rows="4"
                    ></el-input>
                </el-form-item>
                <!-- <el-form-item>
                <el-checkbox v-model="checked" :disabled="disabled">同步报备阿里</el-checkbox>
            </el-form-item> -->
            </el-form>
        </div>
        <div class="bottom-but">
            <p>
                <el-button
                    round
                    type="primary"
                    size="mini"
                    style="
                        background: none;
                        border-color: #d5d6df;
                        color: #666666;
                    "
                    @click="onClose"
                    >取消</el-button
                >
                <el-button
                    round
                    type="primary"
                    style="
                        background: #2370eb;
                        border-color: #2370eb;
                        color: #fff;
                    "
                    size="mini"
                    @click="onSubmit(3)"
                    >申请报备</el-button
                >
            </p>
        </div>
    </div>
</template>
<script>
import { provinceAndCityData } from 'element-china-area-data';
import {
    customerList,
    reportList,
    reportDelete,
    verifyDomain,
} from '@/api/report/report';
import { reportUpdate, accountGet } from '@/api/report/newReport';
import { contactAdd } from '@/api/user/ae/customer';
import {
    addCustomer,
    getCustomerAdmin,
    customerAddMy,
} from '@/api/user/ae/customer';
import { getCustomerId } from '@/api/user/ae/customer';
import { nameGet, roleList } from '@/api/customer/customer.js';
import { channeManagelList } from '@/api/reportchannel/reportchannel.js';
export default {
    data() {
        return {
            form: {
                customerId: '',
                contactName: '',
                contactPhone: '',
                reportName: '',
                reportDomain: '',
                reportReason: '',
                estimateNumber: '',
                estimateTerm: '',
                estimateCooperateTime: '',
                estimateAmount: '',
                reportRemark: '',

                enterpriseType: 1,
            },
            option: provinceAndCityData,
            addressArr: [],
            options: [],
            optionsFilter: [],
            contactList: [],
            value: {},
            contactData: {},
            phoneList: [],
            contactPhoneData: {},
            radio: 1,
            isAddContact: false,
            isDomain: false,
            isName: false,
            dialogDelete: false,
            account: {},
            checkDomainText: '',
            tag1: '',
            enterpriseType: '',
            aiChaStatus: 1,
            companyList: [],
            tableRadio: {},
            channelListData: [],
            userName: [],
            adminIdList: [{}],
            chanceCollaboratorDOList: [],
        };
    },
    methods: {
        // 获取部门成员
        getUserList() {
            let data = {
                param: {
                    configAdminId: sessionStorage.getItem('adminId')
                },
            };
            roleList(data).then((res) => {
                this.userName = res.data;
            });
        },
        remoteMethod(query) {
            this.getData(query);
            this.nameGet(query);
            // this.value = query;
            let data = {
                param: {
                    companyName: query,
                    enterpriseTypeList: [1, 3],
                },
            };
            getCustomerAdmin(data).then((res) => {
                if (res.code == 200) {
                    if (res.data.list && res.data.list.length > 0) {
                        this.tag1 = false;
                        this.companyList = res.data.list;
                    } else {
                        this.tag1 = true;
                        this.companyList = [];
                    }
                }
            });
        },
        async echoData(row) {
            sessionStorage.setItem('customerlist', 1);
            this.options = [];
            this.contactData = {};
            this.contactList = [];
            this.phoneList = [];
            this.contactPhoneData = {};
            this.value = '';
            this.addressArr = [];
            this.adminIdList = [{}];
            this.isAddContact = false;
            this.form = row;
            this.chanceCollaboratorDOList = [{}];
            if (row.enterpriseType != 3) {
                if (row.provinceId && row.cityId) {
                    this.addressArr = ['' + row.provinceId, '' + row.cityId];
                } else if (row.provinceId && !row.cityId) {
                    this.addressArr = ['' + row.provinceId];
                }
            }
            if (row.enterpriseType == 3) {
                this.form.abroadArea = row.abroadArea;
            }
            let obj = {
                param: {
                    productType: 5,
                    adminId: sessionStorage.getItem('adminId'),
                },
            };
            accountGet(obj).then((res) => {
                this.account = res.data;
            });
            let data = {
                param: {
                    id: this.value.id,
                },
            };
            getCustomerId(data).then((res) => {
                this.enterpriseType = res.data.enterpriseType;
                this.form.enterpriseType = res.data.enterpriseType;
            });
            this.form = JSON.parse(JSON.stringify(this.form));
            row.chanceCollaboratorBOList &&
            row.chanceCollaboratorBOList.length > 0
                ? (this.chanceCollaboratorDOList = row.chanceCollaboratorBOList)
                : '';

            this.getData(row.companyName);
        },

        getData(name) {
            let data = {
                param: {
                    adminId: sessionStorage.getItem('adminId'),
                    enterpriseTypeList: [1, 5],
                },
                pageNum: 1,
                pageSize: 20,
            };
            if (name) {
                data.param.name = name;
            }
            getCustomerAdmin(data).then((res) => {
                this.options = res.data.list;
                this.optionsFilter = res.data.list;
                console.log(this.options);
                this.getEchoContact(this.options);
            });
            this.getUserList();
        },
        getEchoContact(options) {
            if (typeof this.value == 'string' && this.value !== '') {
                return;
            }
            this.value = options[0];
            console.log(this.value);
            if (this.value && this.value.customerContactList) {
                this.contactList = this.value.customerContactList;
            }

            if (
                this.contactList.find(
                    (v) => v.contactName == this.form.contactName
                ) !== undefined
            ) {
                this.contactList.forEach((item) => {
                    if (item.contactName == this.form.contactName) {
                        this.phoneList = item.customerContactWayDOList;
                        item.customerContactWayDOList.forEach((itm) => {
                            if (
                                itm.contactContent == this.form.contactPhone &&
                                itm.contactWay == 1
                            ) {
                                this.contactData = item.contactName;
                                this.contactPhoneData = itm.contactContent;
                                this.radio = 1;
                            }
                        });
                    } else {
                        this.contactData = this.form.contactName;
                        this.contactPhoneData = this.form.contactPhone;
                        return;
                    }
                });
            } else {
                this.radio = 2;
                this.contactData = this.form.contactName;
                this.contactPhoneData = this.form.contactPhone;
                this.isAddContact = true;
            }
        },
        dataFilter(val) {
            this.value = val;
            if (val) {
                this.options = this.optionsFilter.filter((item) => {
                    if (
                        item.companyName.includes(val) ||
                        item.companyName
                            .toUpperCase()
                            .includes(val.toUpperCase())
                    ) {
                        return true;
                    }
                });
            } else {
                this.options = this.optionsFilter;
            }
        },

        getCompanyData(row) {
            if (!this.contactData.id) {
                this.contactData = '';
            } else {
                this.contactData = {};
            }
            if (!this.contactPhoneData.contactId) {
                this.contactPhoneData = '';
            } else {
                this.contactPhoneData = {};
            }

            if (this.value.constructor === Object) {
                this.form.reportName = this.value.companyName;
                this.contactList = [];
                this.radio = 1;
                this.form.customerId = this.value.id;
                this.form.companyName = this.value.companyName;
                this.form.reportDomain = this.value.companyDomain;
                this.form.reportName = this.value.companyName;
                this.form.contactPhone;
                this.form.term = this.value.term;
                this.form.number = this.value.number;
                this.addressArr = [];
                this.isDomainInp = true;
                this.domainList = [];
                this.form.enterpriseType = 1;
                let data = {
                    param: {
                        id: this.value.id,
                    },
                };
                getCustomerId(data).then((res) => {
                    this.enterpriseType = res.data.enterpriseType;
                    this.form.enterpriseType = res.data.enterpriseType;
                    if (res.data.companyDomain) {
                        this.domainList.push(res.data.companyDomain);
                    }
                    if (res.data.customerDomainList.length > 0) {
                        for (
                            let i = 0;
                            i < res.data.customerDomainList.length;
                            i++
                        )
                            this.domainList.push(
                                res.data.customerDomainList[i].domain
                            );
                    }
                    this.nameGet(this.value.companyName);
                });

                this.onCheckName();
                if (this.value.provinceId) {
                    this.addressArr.push(
                        String(this.value.provinceId),
                        String(this.value.cityId)
                    );
                }
                this.contactList = this.value.customerContactList;
            } else {
                this.isDomainInp = false;
                this.radio = 2;
                this.form.reportName = this.value;
                this.nameGet(this.value);
                this.onCheckName();
            }
        },
        nameGet(e) {
            let data = {
                param: {
                    companyName: e,
                },
            };
            nameGet(data).then((res) => {
                if (res.code == 200) {
                    this.aiChaStatus = res.data.aiChaStatus;
                }
            });
        },
        // 获取联系人电话
        getContactPhone(row) {
            console.log(this.contactData);
            if (!this.contactData.id) {
                this.isAddContact = true;
            }

            if (!this.contactPhoneData.contactId) {
                this.contactPhoneData = '';
            } else {
                this.contactPhoneData = {};
            }

            this.phoneList = [];
            for (let i in this.contactData.customerContactWayDOList) {
                if (
                    this.contactData.customerContactWayDOList[i].contactWay == 1
                ) {
                    this.phoneList.push(
                        this.contactData.customerContactWayDOList[i]
                    );
                }
            }
        },
        getPhoneData() {
            if (!this.contactPhoneData.contactId) {
                this.isAddContact = true;
            }
        },
        // 报备名称校验
        onCheckName(query) {
            if (query) {
                this.form.reportName = query;
            }
            this.isName = false;
            if (this.form.reportName) {
                let data = {
                    param: {
                        reportName: this.form.reportName,
                        productType: 1,
                        reportStatusList: [3, 8, 9],
                    },
                };
                reportList(data).then((res) => {
                    console.log(res.data);
                    if (!res.data.list || res.data.list.length == 0) {
                        this.isName = true;
                    } else {
                        this.isName = true;
                        for (let i in res.data.list) {
                            if (
                                res.data.list[i].adminId !=
                                sessionStorage.getItem('adminId')
                            ) {
                                console.log('2');
                                return (this.isName = false);
                            }
                        }
                    }
                });
            }
        },
        addChanceCollaborator() {
            if (this.chanceCollaboratorDOList.length == 5) {
                return this.$message.error('合作人最多添加5个');
            }
            this.chanceCollaboratorDOList.push({
                collaboratorAdminId: '',
            });
        },
        delChanceCollaborator(index) {
            this.chanceCollaboratorDOList.splice(index, 1);
        },
        chnageCollaborator(val, index) {
            if (
                this.chanceCollaboratorDOList.some((item, idx) => {
                    return item.collaboratorAdminId == val && index != idx;
                })
            ) {
                this.chanceCollaboratorDOList[index].collaboratorAdminId = '';
                return this.$message.error('合作人重复，请重新选择');
            }
        },
        // 域名校验
        onCheckDomain() {
            this.isDomain = false;
            this.checkDomainText = '本域名重复，请修改';
            console.log(this.form.reportDomain.indexOf('www') == -1);
            if (
                this.form.reportDomain &&
                this.form.reportDomain.indexOf('www') == -1
            ) {
                return (
                    (this.isDomain = false),
                    (this.checkDomainText = '域名必须www开头')
                );
            }
            if (
                this.form.reportDomain &&
                this.form.reportDomain.indexOf('www') == 0
            ) {
                let data = {
                    param: {
                        productType: 1,
                        domainList: [this.form.reportDomain],
                    },
                };
                verifyDomain(data).then((res) => {
                    if (res.code == 200) {
                        this.isDomain = true;
                    }
                });
            }
        },
        onSubmit(i) {
            if (this.form.enterpriseType != 3) {
                this.form.provinceId = Number(this.addressArr[0]) || 0;
                this.form.cityId = Number(this.addressArr[1]) || 0;
            }
            if (this.form.nicheCategory == 1) {
                if (
                    (this.enterpriseType == 1 && this.aiChaStatus != 1) ||
                    (this.aiChaStatus != 1 && this.form.enterpriseType == 1)
                ) {
                    if (i == 8) {
                        return this.$message.error(
                            '客户名称有误，无法对客户进行报备'
                        );
                    }
                }
                if (this.radio == 2 && !this.form.contactName) {
                    return this.$message.error('请选择或输入联系人');
                } else if (this.radio == 2 && !this.form.contactPhone) {
                    return this.$message.error('请选择或输入联系手机号');
                } else if (this.radio == 1 && !this.contactData) {
                    return this.$message.error('请选择或输入联系人');
                } else if (this.radio == 1 && !this.contactPhoneData) {
                    return this.$message.error('请选择或输入联系手机号');
                } else if (
                    this.form.enterpriseType != 3 &&
                    !this.form.provinceId
                ) {
                    return this.$message.error('请选择所在地');
                } else if (
                    this.form.enterpriseType == 3 &&
                    !this.form.abroadArea
                ) {
                    return this.$message.error('请输入所在地');
                } else if (!this.form.reportReason) {
                    return this.$message.error('请输入报备理由');
                } else if (!this.form.estimateNumber) {
                    return this.$message.error('请选择预计用户数');
                } else if (!this.form.estimateTerm) {
                    return this.$message.error('请选择预计年限');
                }
                if (this.addressArr.length == 1) {
                    this.addressArr.push('0');
                }
                if (this.radio == 2) {
                    if (this.companyList.length > 0) {
                        let data = {
                            param: {
                                id: this.tableRadio.id,
                            },
                        };
                        customerAddMy(data).then((res) => {
                            if (res.code == 200) {
                            }
                        });
                    } else {
                        let data = {
                            param: {
                                customerDO: {
                                    provinceId: this.addressArr[0],
                                    cityId: this.addressArr[1],
                                    enterpriseType: this.form.enterpriseType,
                                },
                                customerContactList: [
                                    {
                                        contactName: this.form.contactName,
                                        customerContactWayDOList: [
                                            {
                                                contactContent:
                                                    this.form.contactPhone,
                                                contactWay: 1,
                                            },
                                        ],
                                    },
                                ],
                            },
                        };
                        if (typeof this.value == 'object') {
                            data.param.customerDO.companyName = this.Turn(
                                this.value.companyName
                            );
                            data.param.customerContactList[0].companyName =
                                this.Turn(this.value.companyName);
                        } else {
                            data.param.customerDO.companyName = this.Turn(
                                this.value
                            );
                            data.param.customerContactList[0].companyName =
                                this.Turn(this.value);
                        }
                        addCustomer(data).then((res) => {
                            if (res.code == 200) {
                                let obj = {
                                    param: {
                                        customerId: res.data.id,
                                        contactName: this.form.contactName,
                                        contactPhone: this.form.contactPhone,
                                        reportName: this.Turn(
                                            this.form.reportName
                                        ),
                                        reportDomain: this.form.reportDomain,
                                        reportReason: this.form.reportReason,
                                        estimateNumber:
                                            this.form.estimateNumber,
                                        estimateTerm: this.form.estimateTerm,
                                        estimateCooperateTime:
                                            this.form.estimateCooperateTime,
                                        estimateCooperateTime:
                                            this.form.estimateCooperateTime,
                                        reportRemark: this.form.reportRemark,
                                        estimateAmount:
                                            this.form.estimateAmount,
                                        provinceId: this.addressArr[0],
                                        cityId: this.addressArr[1],
                                        reportStatus: i,
                                        abroadArea: this.form.abroadArea,
                                        chanceCollaboratorDOList:
                                            this.chanceCollaboratorDOList,
                                    },
                                };
                                this.submit(obj);
                            }
                        });
                    }
                }
                if (this.radio == 1) {
                    let obj = {
                        param: {
                            customerId: this.value.id,
                            contactName: this.contactData.contactName
                                ? this.contactData.contactName
                                : this.contactData,
                            contactPhone: this.contactPhoneData.contactContent
                                ? this.contactPhoneData.contactContent
                                : this.contactPhoneData,
                            reportName: this.Turn(this.form.reportName),

                            reportReason: this.form.reportReason,
                            estimateNumber: this.form.estimateNumber,
                            estimateTerm: this.form.estimateTerm,
                            estimateCooperateTime:
                                this.form.estimateCooperateTime,
                            estimateCooperateTime:
                                this.form.estimateCooperateTime,
                            reportRemark: this.form.reportRemark,
                            estimateAmount: this.form.estimateAmount,
                            provinceId: this.addressArr[0],
                            cityId: this.addressArr[1],
                            reportStatus: i,

                            abroadArea: this.form.abroadArea,
                            chanceCollaboratorDOList:
                                this.chanceCollaboratorDOList,
                        },
                    };
                    obj.param.reportDomain = this.form.reportDomain;
                    obj.param.chanceDomainDOList =
                        this.form.chanceDomainBOList.map((item) => {
                            return {
                                domain: item.domain,
                            };
                        });
                    obj.param.chanceCustomerDOList =
                        this.form.chanceCustomerBOList.map((item) => {
                            return {
                                customerId: item.id,
                            };
                        });
                    this.submit(obj);
                    if (this.isAddContact) {
                        let data = {
                            param: {
                                customerContactDO: {
                                    companyName: this.value.companyName,
                                    contactName: this.contactData.contactName
                                        ? this.contactData.contactName
                                        : this.contactData,
                                    customerContactWayDOList: [
                                        {
                                            contactContent: this
                                                .contactPhoneData.contactContent
                                                ? this.contactPhoneData
                                                      .contactContent
                                                : this.contactPhoneData,
                                            contactWay: 1,
                                        },
                                    ],
                                },
                            },
                        };
                        data.param.customerContactDO.customerId = this.value.id;
                        data.param.customerContactDO.followAdminId =
                            sessionStorage.getItem('adminId');
                        contactAdd(data).then((res) => {});
                    }
                }
            } else {
                if (!this.form.contactName) {
                    return this.$message.error('请输入联系人');
                } else if (!this.form.contactPhone) {
                    return this.$message.error('请输入联系手机号');
                } else if (
                    this.form.enterpriseType != 3 &&
                    !this.form.provinceId
                ) {
                    return this.$message.error('请选择所在地');
                } else if (
                    this.form.enterpriseType == 3 &&
                    !this.form.abroadArea
                ) {
                    return this.$message.error('请输入所在地');
                } else if (!this.form.reportReason) {
                    return this.$message.error('请输入报备理由');
                } else if (!this.form.estimateNumber) {
                    return this.$message.error('请选择预计用户数');
                } else if (!this.form.estimateTerm) {
                    return this.$message.error('请选择预计年限');
                } else {
                    let obj = {
                        param: {
                            channelManageId: this.form.channelManageId,
                            contactName: this.form.contactName,
                            contactPhone: this.form.contactPhone,
                            reportName: this.Turn(this.form.reportName),
                            reportDomain: this.form.reportDomain,
                            reportReason: this.form.reportReason,
                            estimateNumber: this.form.estimateNumber,
                            estimateTerm: this.form.estimateTerm,
                            estimateCooperateTime:
                                this.form.estimateCooperateTime,
                            estimateCooperateTime:
                                this.form.estimateCooperateTime,
                            reportRemark: this.form.reportRemark,
                            estimateAmount: this.form.estimateAmount,
                            provinceId: this.addressArr[0],
                            cityId: this.addressArr[1],
                            reportStatus: i,
                            abroadArea: this.form.abroadArea,
                            chanceCollaboratorDOList:
                                this.chanceCollaboratorDOList,
                        },
                    };
                    obj.param.reportDomain = this.form.reportDomain;
                    obj.param.chanceDomainDOList =
                        this.form.chanceDomainBOList.map((item) => {
                            return {
                                domain: item.domain,
                            };
                        });
                    obj.param.chanceCustomerDOList =
                        this.form.chanceCustomerBOList.map((item) => {
                            return {
                                customerId: item.id,
                            };
                        });
                    this.submit(obj);
                }
            }
        },
        submit(obj) {
            obj.param.id = this.form.id;
            reportUpdate(obj).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                }
                this.onClose();
            });
        },
        Turn(srt) {
            var reg = /[\(]/g,
                reg2 = /[\)]/g;
            return srt.replace(reg, '（').replace(reg2, '）');
        },
        onDelete() {
            this.dialogDelete = true;
        },
        onSubmitDetele() {
            let data = {
                param: {
                    id: this.form.id,
                },
            };
            reportDelete(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('删除成功');
                    this.dialogDelete = false;
                    this.onClose();
                }
            });
        },
        handleDelete() {
            this.dialogDelete = false;
        },
        onClose() {
            this.$emit('close');
            for (let i in this.form) {
                this.form[i] = '';
            }
            this.chanceCollaboratorDOList = [];
            sessionStorage.removeItem('customerlist');
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.companyList {
    border: 1px solid #e5e5e5;
    margin-bottom: 10px;
    border-radius: 5px;
    overflow: hidden;
    button {
        background: #2370eb;
        border-radius: 13px;
        color: #ffffff;
        margin: 12px;
        margin-left: 40px;
    }
}

.domain_box {
    /deep/ .el-input__inner {
        padding-right: 20px !important;
    }
    .el-button--mini.is-circle {
        padding: 2px;
        background: #fff;
        color: #2370eb;
        font-weight: 600;
        width: 20px;
        height: 20px;
        border: 2px solid #2370eb;
    }

    .domain_update_box {
        position: absolute;
        right: 60px;
        top: 0px;
    }
}
.report {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    .form {
        flex: 1;
        overflow: auto;
    }
    .el-input {
        width: 270px;
    }
    .el-button--mini.is-circle {
        padding: 2px;
        background: #fff;
        color: #2370eb;
        font-weight: 600;
        border: 2px solid #2370eb;
        margin-left: 17px;
    }

    .bottom-but {
        width: 100%;
        line-height: 48px;
        background: #fff;
        border-top: 1px solid #dee0e3;
        z-index: 1000;
        bottom: 0;
        display: flex;
        p {
            margin-left: auto;
            margin-right: 20px;
        }
        button {
            width: 90px;
            z-index: 1000;
        }
    }
    .brand {
        display: inline-block;
        width: 260px;
        padding-left: 10px;
        color: #333333;
        font-size: 14px;
        border-radius: 2px;
    }
}
.content {
    height: 100px;
    text-align: center;
    margin-top: 50px;
    font-size: 16px;
    color: #333;
    line-height: 30px;
}
.but {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
        width: 72px;
    }
}
.domain_rules_box {
    position: absolute;
    right: 80px;
    top: 2px;
    .el-button--mini.is-circle {
        padding: 2px;
        width: 20px;
        height: 20px;
        background-color: transparent;
        border: none;
        margin-left: 0;
        font-size: 16px;
        font-weight: 600;
    }
    .el-icon-success {
        color: #46a136 !important;
    }
    .el-icon-warning {
        color: #f5a623 !important;
    }
}
.domain_rules_box2 {
    position: absolute;
    right: 23px;
    top: 0px;
    span {
        font-size: 12px;
        color: #f5a623;
    }
    .el-button--mini.is-circle {
        padding: 2px;
        width: 20px;
        height: 20px;
        background-color: transparent;
        border: none;
        margin-left: 0;
        font-size: 13px;
        font-weight: 600;
    }
    .el-button--mini.is-circle.el-icon-success {
        color: #46a136 !important;
        margin-left: -40px;
        font-size: 16px;
        top: 2px;
    }
    .el-icon-warning {
        color: #f5a623 !important;
    }
}
</style>
<style>
.report .el-form-item {
    margin-bottom: 10px !important;
}
</style>
