<template>
    <div class="report">
        <el-form
            ref="form"
            style="
                flex: 1;
                margin-top: 20px;
                margin-left: 40px;
                margin-bottom: 50px;
                padding-bottom: 20px;
            "
            label-position="left"
            :model="form"
            label-width="100px"
        >
            <el-form-item label="报备品牌">
                <span class="brand">腾讯企业邮箱</span>
            </el-form-item>
            <el-form-item label="报备名称">
                <span class="brand">{{ data.reportName }}</span>
            </el-form-item>
            <el-form-item label="客户" v-if="data.nicheCategory == 1">
                <span class="brand">{{ data.companyName }}</span>
            </el-form-item>
            <el-form-item label="报备渠道" v-if="data.nicheCategory == 2">
                <span class="brand">{{ data.channelManageName }}</span>
            </el-form-item>

            <el-form-item label="延期天数" required>
                <el-input
                    v-model="form.delayDays"
                    size="small"
                    placeholder="请输入延期天数"
                    disabled
                ></el-input>
            </el-form-item>
            <el-form-item
                label="附件"
                v-if="
                    data.chanceFrequencyBOList &&
                    data.chanceFrequencyBOList.length >= 3
                "
                required
            >
                <el-upload
                    drag
                    :action="action"
                    class="avatar-uploader"
                    list-type="picture-card"
                    :on-success="onAttachment"
                    :before-upload="beforeAvatarUploads"
                    multiple
                    :file-list="fileList"
                    ref="attachment"
                    accept=".jpg, .png, .gif, .pdf,.doc,.docx"
                >
                    <i slot="default" class="el-icon-plus"></i>
                    <div
                        slot="file"
                        slot-scope="{ file }"
                        style="text-align: center"
                    >
                        <img
                            class="el-upload-list__item-thumbnail"
                            :src="file.url"
                            v-if="
                                file.url &&
                                (file.url.indexOf('.jpg') != -1 ||
                                    file.url.indexOf('.png') != -1 ||
                                    file.url.indexOf('.gif') != -1)
                            "
                        />
                        <span
                            v-if="
                                file.url &&
                                (file.url.indexOf('.pdf') != -1 ||
                                    file.url.indexOf('.PDF') != -1)
                            "
                        >
                            <img src="@/img/pdfUrl.png" alt="" />
                        </span>
                        <span v-if="file.url && file.url.indexOf('.doc') != -1">
                            <img src="@/img/word.png" alt="" />
                        </span>
                        <span class="el-upload-list__item-actions">
                            <span
                                class="el-upload-list__item-preview"
                                @click="handlePictureCardPreview(file)"
                                v-if="
                                    file.url &&
                                    (file.url.indexOf('.jpg') != -1 ||
                                        file.url.indexOf('.png') != -1 ||
                                        file.url.indexOf('.gif') != -1)
                                "
                            >
                                <i class="el-icon-zoom-in"></i>
                            </span>
                            <span
                                class="el-upload-list__item-delete"
                                @click="handleDownload(file)"
                            >
                                <i class="el-icon-download"></i>
                            </span>
                            <span
                                class="el-upload-list__item-delete"
                                @click="handleRemoves(file)"
                            >
                                <i class="el-icon-delete"></i>
                            </span>
                        </span>
                    </div>
                </el-upload>
            </el-form-item>
            <el-form-item
                style="margin-top: -20px"
                v-if="
                    fileArr.length == 0 &&
                    data.chanceFrequencyBOList &&
                    data.chanceFrequencyBOList.length >= 3
                "
            >
                <span class="upload_toast">
                    <i class="el-icon-warning-outline"></i>
                    请上传合同及其他销售材料
                </span>
            </el-form-item>
            <el-form-item label="备注">
                <el-input
                    type="textarea"
                    v-model="form.applyRemark"
                    size="small"
                    placeholder="请输入备注"
                    style="width: 270px"
                    :rows="4"
                ></el-input>
            </el-form-item>
        </el-form>

        <div class="bottom-but">
            <el-button
                round
                type="primary"
                size="mini"
                style="
                    background: none;
                    border-color: #d5d6df;
                    color: #666666;
                    margin-left: auto;
                "
                @click="onClose"
                >取消</el-button
            >

            <el-button
                round
                type="primary"
                style="
                    background: #2370eb;
                    border-color: #2370eb;
                    color: #fff;
                    margin-right: 12px;
                "
                size="mini"
                @click="onSubmit"
                >确认</el-button
            >
        </div>
    </div>
</template>
<script>
import { reportApplyAudit } from '@/api/report/newReport';
export default {
    data() {
        return {
            data: {},
            form: {
                delayDays: 45,
                applyRemark: '',
            },
            action: this.$url.url() + '/file/local/upload/contract',
            fileList: [],
            contractAttachmentList: [],
            fileArr: [],
            userType: sessionStorage.getItem('userType'),
        };
    },
    methods: {
        getData(row) {
            console.log(row);
            this.data = row;
        },

        onSubmit() {
            if (!this.form.delayDays) {
                return this.$message.error('请输入延期天数');
            }
            if (
                this.data.chanceFrequencyBOList &&
                this.data.chanceFrequencyBOList.length >= 3 &&
                !(this.fileArr && this.fileArr.length > 0)
            ) {
                return this.$message.error('请上传附件');
            }

            let data = {
                param: {
                    chanceDO: {
                        id: this.data.id,
                    },
                    chanceApplyDO: {
                        delayDays: this.form.delayDays,
                        delayRemark: this.form.applyRemark,
                    },
                },
            };
            if (
                this.userType == 8 ||
                this.userType == 9 ||
                this.userType == 10
            ) {
                data.param.chanceDO.reportStatus = 20;
            } else {
                data.param.chanceDO.reportStatus = 11;
            }

            if (this.fileArr && this.fileArr.length > 0) {
                data.param.chanceApplyDO.attachmentUrl = JSON.stringify(
                    this.fileArr.map((item) => {
                        return {
                            url: item.url,
                        };
                    })
                );
            }
            reportApplyAudit(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    for (let i in this.form) {
                        this.form[i] = '';
                    }
                    this.$emit('close', 1);
                }
            });
            // let data = {
            //     param: {
            //         id: this.data.id,
            //         reportStatus: 11,
            //         delayDays: this.form.delayDays,
            //         applyRemark: this.form.applyRemark,
            //     },
            // };
            // reportUpdate(data).then((res) => {
            //     if (res.code == 200) {
            //         this.$message.success('操作成功');
            //         for (let i in this.form) {
            //             this.form[i] = '';
            //         }
            //         this.form.delayDays = 30;
            //         this.$emit('close', 1);
            //     }
            // });
        },
        // 上传附件
        onAttachment(response) {
            this.fileArr.push({ url: response.data });
            setTimeout(() => {
                this.fileList = this.fileArr;
                if (this.fileList.length == this.limit) {
                    this.hideUploads = true;
                }
            }, 2000);
        },
        beforeAvatarUploads(file) {
            const isLt2M = file.size / 1024 / 1024 < 20;
            const isLength = this.fileArr.length < 3;
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 20MB!');
            }
            if (!isLength) {
                this.$message.error('最多上传3个附件');
            }
            return isLt2M && isLength;
        },
        // 附件删除
        handleRemoves(file) {
            for (let i = 0; i < this.fileList.length; i++) {
                if (this.fileList[i].uid == file.uid) {
                    this.fileArr.splice(i, 1);
                }
            }
            this.fileList = this.fileArr;
            this.hideUploads = false;
        },
        // 预览图片
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        // 下载
        handleDownload(file) {
            if (
                file.url.indexOf('.doc') != -1 ||
                file.url.indexOf('.docx') != -1
            ) {
                window.open(
                    'https://view.officeapps.live.com/op/view.aspx?src=' +
                        file.url
                );
            } else {
                window.open(file.url, '_blank');
            }
        },
        // onPreview(url) {
        //     if (url.indexOf('.doc') != -1 || url.indexOf('.docx') != -1) {
        //         window.open(
        //             'https://view.officeapps.live.com/op/view.aspx?src=' + url
        //         );
        //     } else {
        //         window.open(url, '_blank');
        //     }
        // },
        onClose() {
            this.$emit('close');
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
/deep/ .avatar-uploader .el-upload {
    border: none;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    background: #f3f6fb;
    width: 82px;
    height: 82px;
    line-height: 82px;
    text-align: center;
}
/deep/.el-upload-dragger {
    width: 82px;
    height: 82px;
    line-height: 82px;
    text-align: center;
}
/deep/ .avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
/deep/.avatar-uploader-icon {
    color: #8c939d;
    width: 82px;
    height: 82px;
    line-height: 80px;
    text-align: center;
}
/deep/ .el-upload-list__item.is-success {
    width: 82px !important;
    height: 82px !important;
}
.report {
    width: 100%;
    display: flex;
    flex-direction: column;
    .el-input {
        width: 270px;
    }
    .el-button--mini.is-circle {
        padding: 2px;
        background: #fff;
        color: #2370eb;
        font-weight: 600;
        border: 2px solid #2370eb;
        margin-left: 17px;
    }

    .bottom-but {
        width: 100%;
        height: 48px;
        border-top: 1px solid #e5e5e5;
        display: flex;
        align-items: center;
        justify-content: space-between;
        button {
            min-width: 72px;
        }
    }
    .brand {
        display: inline-block;
        width: 260px;
        padding-left: 10px;
        background: #fafafa;
        color: #333333;
        font-size: 14px;
        border-radius: 2px;
    }
}
.upload_toast {
    font-size: 12px;
    i {
        color: #f7b84f;
    }
}
</style>
