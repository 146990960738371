<template>
    <div class="trial">
        <el-form
            ref="form"
            style="flex: 1; margin-top: 20px; margin-left: 40px"
            label-position="left"
            :model="form"
            label-width="100px"
        >
            <el-form-item label="报备品牌">
                <span class="brand">腾讯企业邮箱</span>
            </el-form-item>
            <el-form-item label="报备名称">
                <span class="brand">{{ data.reportName }}</span>
            </el-form-item>
            <el-form-item label="客户" v-if="data.nicheCategory == 1">
                <span class="brand">{{ data.companyName }}</span>
            </el-form-item>
            <el-form-item label="报备渠道" v-if="data.nicheCategory == 2">
                <span class="brand">{{ data.channelManageName }}</span>
            </el-form-item>
            <el-form-item label="邮箱版本">
                <span class="brand">{{
                    data.emailVersionType == 2
                        ? '新版'
                        : data.emailVersionType == 3
                        ? '旧版'
                        : '- -'
                }}</span>
            </el-form-item>

            <el-form-item
                v-show="data.emailVersionType != ''"
                :label="
                    data.emailVersionType == 3
                        ? '管理员账号'
                        : data.emailVersionType == 2
                        ? '企业ID'
                        : ''
                "
            >
                <span class="brand">{{ data.emailVersionTypeName }}</span>
            </el-form-item>
            <el-form-item label="延期天数" required>
                <el-input
                    v-model="form.delayDays"
                    size="small"
                    placeholder="请输入延期天数"
                    clearable
                    @input="
                        () => {
                            form.delayDays = form.delayDays.replace(/[^0-9]/g, '');
                        }
                    "
                ></el-input>
            </el-form-item>
            <el-form-item label="备注">
                <el-input
                    type="textarea"
                    v-model="form.applyRemark"
                    size="small"
                    placeholder="请输入备注"
                    style="width: 270px"
                ></el-input>
            </el-form-item>
        </el-form>
        <div class="bottom-but">
            <el-button
                round
                type="primary"
                size="mini"
                style="background: none; border-color: #d5d6df; color: #666666; margin-left: 270px"
                @click="onClose"
                >取消</el-button
            >

            <el-button
                round
                type="primary"
                style="background: #2370eb; border-color: #2370eb; color: #fff"
                size="mini"
                @click="onSubmit"
                >申请延期</el-button
            >
        </div>
    </div>
</template>
<script>
import { reportUpdate } from '@/api/report/report';
export default {
    data() {
        return {
            data: {},
            form: {
                delayDays: '',
                applyRemark: '',
            },
        };
    },
    methods: {
        echoData(row) {
            this.data = row;
        },
        onSubmit() {
            if (!this.form.delayDays) {
                return this.$message.error('请输入延期天数');
            }
            let data = {
                param: {
                    id: this.data.id,
                    trialStatus: 13,
                    delayDays: this.form.delayDays,
                    applyRemark: this.form.applyRemark,
                },
            };
            reportUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.onClose();
                    for (let i in this.form) {
                        this.form[i] = '';
                    }
                    this.$emit('close', 1);
                }
            });
        },

        onClose() {
            this.$emit('close');
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.trial {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    .brand {
        display: inline-block;
        width: 260px;
        padding-left: 10px;
        background: #fafafa;
        margin-left: 10px;
        color: #333333;
        font-size: 14px;
        border-radius: 2px;
    }
    .el-input {
        width: 270px;
    }
    .bottom-but {
        width: 100%;
        line-height: 48px;
        background: #fff;
        border-top: 1px solid #dee0e3;
        z-index: 1000;
        bottom: 0;
        position: fixed;
        button {
            width: 90px;
            z-index: 1000;
        }
    }
}
</style>
