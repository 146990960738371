var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"report"},[_c('el-form',{ref:"form",staticStyle:{"flex":"1","margin-top":"20px","margin-left":"40px","margin-bottom":"50px","padding-bottom":"20px"},attrs:{"label-position":"left","model":_vm.form,"label-width":"100px"}},[_c('el-form-item',{attrs:{"label":"报备品牌"}},[_c('span',{staticClass:"brand"},[_vm._v("腾讯企业邮箱")])]),_c('el-form-item',{attrs:{"label":"报备名称"}},[_c('span',{staticClass:"brand"},[_vm._v(_vm._s(_vm.data.reportName))])]),(_vm.data.nicheCategory == 1)?_c('el-form-item',{attrs:{"label":"客户"}},[_c('span',{staticClass:"brand"},[_vm._v(_vm._s(_vm.data.companyName))])]):_vm._e(),(_vm.data.nicheCategory == 2)?_c('el-form-item',{attrs:{"label":"报备渠道"}},[_c('span',{staticClass:"brand"},[_vm._v(_vm._s(_vm.data.channelManageName))])]):_vm._e(),_c('el-form-item',{attrs:{"label":"延期天数","required":""}},[_c('el-input',{attrs:{"size":"small","placeholder":"请输入延期天数","disabled":""},model:{value:(_vm.form.delayDays),callback:function ($$v) {_vm.$set(_vm.form, "delayDays", $$v)},expression:"form.delayDays"}})],1),(
                _vm.data.chanceFrequencyBOList &&
                _vm.data.chanceFrequencyBOList.length >= 3
            )?_c('el-form-item',{attrs:{"label":"附件","required":""}},[_c('el-upload',{ref:"attachment",staticClass:"avatar-uploader",attrs:{"drag":"","action":_vm.action,"list-type":"picture-card","on-success":_vm.onAttachment,"before-upload":_vm.beforeAvatarUploads,"multiple":"","file-list":_vm.fileList,"accept":".jpg, .png, .gif, .pdf,.doc,.docx"},scopedSlots:_vm._u([{key:"file",fn:function(ref){
            var file = ref.file;
return _c('div',{staticStyle:{"text-align":"center"}},[(
                            file.url &&
                            (file.url.indexOf('.jpg') != -1 ||
                                file.url.indexOf('.png') != -1 ||
                                file.url.indexOf('.gif') != -1)
                        )?_c('img',{staticClass:"el-upload-list__item-thumbnail",attrs:{"src":file.url}}):_vm._e(),(
                            file.url &&
                            (file.url.indexOf('.pdf') != -1 ||
                                file.url.indexOf('.PDF') != -1)
                        )?_c('span',[_c('img',{attrs:{"src":require("@/img/pdfUrl.png"),"alt":""}})]):_vm._e(),(file.url && file.url.indexOf('.doc') != -1)?_c('span',[_c('img',{attrs:{"src":require("@/img/word.png"),"alt":""}})]):_vm._e(),_c('span',{staticClass:"el-upload-list__item-actions"},[(
                                file.url &&
                                (file.url.indexOf('.jpg') != -1 ||
                                    file.url.indexOf('.png') != -1 ||
                                    file.url.indexOf('.gif') != -1)
                            )?_c('span',{staticClass:"el-upload-list__item-preview",on:{"click":function($event){return _vm.handlePictureCardPreview(file)}}},[_c('i',{staticClass:"el-icon-zoom-in"})]):_vm._e(),_c('span',{staticClass:"el-upload-list__item-delete",on:{"click":function($event){return _vm.handleDownload(file)}}},[_c('i',{staticClass:"el-icon-download"})]),_c('span',{staticClass:"el-upload-list__item-delete",on:{"click":function($event){return _vm.handleRemoves(file)}}},[_c('i',{staticClass:"el-icon-delete"})])])])}}],null,false,1790026579)},[_c('i',{staticClass:"el-icon-plus",attrs:{"slot":"default"},slot:"default"})])],1):_vm._e(),(
                _vm.fileArr.length == 0 &&
                _vm.data.chanceFrequencyBOList &&
                _vm.data.chanceFrequencyBOList.length >= 3
            )?_c('el-form-item',{staticStyle:{"margin-top":"-20px"}},[_c('span',{staticClass:"upload_toast"},[_c('i',{staticClass:"el-icon-warning-outline"}),_vm._v(" 请上传合同及其他销售材料 ")])]):_vm._e(),_c('el-form-item',{attrs:{"label":"备注"}},[_c('el-input',{staticStyle:{"width":"270px"},attrs:{"type":"textarea","size":"small","placeholder":"请输入备注","rows":4},model:{value:(_vm.form.applyRemark),callback:function ($$v) {_vm.$set(_vm.form, "applyRemark", $$v)},expression:"form.applyRemark"}})],1)],1),_c('div',{staticClass:"bottom-but"},[_c('el-button',{staticStyle:{"background":"none","border-color":"#d5d6df","color":"#666666","margin-left":"auto"},attrs:{"round":"","type":"primary","size":"mini"},on:{"click":_vm.onClose}},[_vm._v("取消")]),_c('el-button',{staticStyle:{"background":"#2370eb","border-color":"#2370eb","color":"#fff","margin-right":"12px"},attrs:{"round":"","type":"primary","size":"mini"},on:{"click":_vm.onSubmit}},[_vm._v("确认")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }