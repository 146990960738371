<template>
    <div class="trial">
        <el-form
            ref="form"
            style="flex: 1; margin-top: 20px; margin-left: 40px"
            label-position="left"
            :model="form"
            label-width="100px"
        >
            <el-form-item label="报备品牌" required style="margin-left: -10px">
                <span class="brand">腾讯企业邮箱</span>
            </el-form-item>
            <el-form-item label="报备名称" required style="margin-left: -10px">
                <span class="brand">{{ form.reportName }}</span>
            </el-form-item>
            <el-form-item
                label="客户"
                v-if="form.nicheCategory == 1"
                required
                style="margin-left: -10px"
            >
                <span class="brand">{{ form.companyName }}</span>
            </el-form-item>
            <el-form-item
                label="报备渠道"
                v-if="form.nicheCategory == 2"
                required
                style="margin-left: -10px"
            >
                <span class="brand">{{ form.channelManageName }}</span>
            </el-form-item>
            <el-form-item label="用户数" required style="margin-left: -10px">
                <el-input
                    style="margin-left: 10px"
                    v-model="form.trialNumber"
                    size="small"
                    placeholder="请输入用户数"
                    @input="
                        () => {
                            form.trialNumber = form.trialNumber.replace(/[^0-9]/g, '');
                        }
                    "
                    clearable
                ></el-input>
            </el-form-item>
            <el-form-item label="试用天数" required style="margin-left: -10px">
                <el-input
                    style="margin-left: 10px"
                    v-model="form.trialDays"
                    size="small"
                    placeholder="请输入试用天数"
                    clearable
                    @input="
                        () => {
                            form.trialDays = form.trialDays.replace(/[^0-9]/g, '');
                        }
                    "
                ></el-input>
            </el-form-item>
            <el-form-item label="邮箱版本" required style="margin-left: -10px">
                <el-select
                    v-model="form.emailVersionType"
                    size="small"
                    style="width: 270px; margin-left: 10px"
                    placeholder="请选择邮箱版本"
                    clearable
                    @change="form.emailVersionTypeName = ''"
                >
                    <el-option label="旧版" :value="3"></el-option>
                    <el-option label="新版" :value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item
                required
                v-show="form.emailVersionType != ''"
                :label="
                    form.emailVersionType == 3
                        ? '管理员账号'
                        : form.emailVersionType == 2
                        ? '企业ID'
                        : ''
                "
                style="margin-left: -10px"
            >
                <el-input
                    style="margin-left: 10px"
                    v-model="form.emailVersionTypeName"
                    size="small"
                    clearable
                    :placeholder="
                        form.emailVersionType == 3
                            ? '请输入管理员账号'
                            : form.emailVersionType == 2
                            ? '请输入企业ID'
                            : ''
                    "
                ></el-input>
            </el-form-item>

            <el-form-item label="备注">
                <el-input
                    type="textarea"
                    v-model="form.applyRemark"
                    size="small"
                    placeholder="请输入备注"
                    style="width: 270px"
                ></el-input>
            </el-form-item>
        </el-form>
        <div class="bottom-but">
            <el-button
                round
                type="primary"
                size="mini"
                style="background: none; border-color: #d5d6df; color: #666666; margin-left: 270px"
                @click="onClose"
                >取消</el-button
            >

            <el-button
                round
                type="primary"
                style="background: #2370eb; border-color: #2370eb; color: #fff"
                size="mini"
                @click="onSubmit"
                >申请试用</el-button
            >
        </div>
    </div>
</template>
<script>
import { reportUpdate } from '@/api/report/report';
export default {
    data() {
        return {
            form: {
                id: 0,
                customerId: 0,
                companyName: '',
                reportName: '',
                emailVersionType: 2,
                emailVersionTypeName: '',
                trialNumber: '',
                trialDays: '',
                applyRemark: '',
            },
            trialStatus: '',
        };
    },
    methods: {
        echoData(data) {
            this.trialStatus = '';
            this.form.companyName = data.companyName;
            this.form.reportName = data.reportName;
            this.form.customerId = data.customerId;
            this.form.id = data.id;
            if (data.emailVersionType == 2 || data.emailVersionType == 3) {
                this.form.emailVersionType = data.emailVersionType;
                this.form.emailVersionTypeName = data.emailVersionTypeName;
            }
        },
        getData(data) {
            console.log(data);
            this.form.companyName = data.companyName;
            this.form.reportName = data.reportName;
            this.form.customerId = data.customerId;
            this.form.id = data.id;
            if (data.emailVersionType == 2 || data.emailVersionType == 3) {
                this.form.emailVersionType = data.emailVersionType;
                this.form.emailVersionTypeName = data.emailVersionTypeName;
            }
            this.form.trialNumber = data.trialNumber;
            this.form.trialDays = data.trialDays;
            this.form.applyRemark = data.applyRemark;
            this.trialStatus = 18;
        },
        onSubmit() {
            if (!this.form.trialNumber) {
                this.$message.error('请输入用户数');
            } else if (!this.form.trialDays) {
                this.$message.error('请输入试用天数');
            } else if (!this.form.emailVersionType) {
                this.$message.error('请选择邮箱版本');
            } else if (this.form.emailVersionType == 3 && !this.form.emailVersionTypeName) {
                this.$message.error('请输入管理员账号');
            } else if (this.form.emailVersionType == 2 && !this.form.emailVersionTypeName) {
                this.$message.error('请输入企业ID');
            } else {
                let data = {
                    param: this.form,
                };
                data.param.trialStatus = 7;
                if (this.trialStatus == 18) {
                    data.param.trialStatus = 18;
                }
                reportUpdate(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('申请成功');
                        this.$emit('close', 1);
                        for (let i in this.form) {
                            this.form[i] = '';
                        }
                        this.trialStatus = '';
                        this.form.emailVersionType = 3;
                    }
                });
            }
        },
        onClose() {
            this.$emit('close');
            for (let i in this.form) {
                this.form[i] = '';
            }
            this.trialStatus = '';
            this.form.emailVersionType = 3;
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.trial {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    .brand {
        display: inline-block;
        width: 260px;
        padding-left: 10px;
        background: #fafafa;
        margin-left: 10px;
        color: #333333;
        font-size: 14px;
        border-radius: 2px;
    }
    .el-input {
        width: 270px;
    }
    .bottom-but {
        width: 100%;
        line-height: 48px;
        background: #fff;
        border-top: 1px solid #dee0e3;
        z-index: 1000;
        bottom: 0;
        position: fixed;
        button {
            width: 90px;
            z-index: 1000;
        }
    }
}
</style>
