<template>
    <div class="customer">
        <div class="search-box">
            <div class="search-one">
                <div class="left">
                    <!-- <p>
                        <span>报备库</span>
                        <el-select
                            v-model="search.libraryCategoryVal"
                            size="small"
                            style="width: 100px"
                            clearable
                            placeholder="全部"
                            @change="(pagesize = 20), (currentPage = 1), getTableData(isStsts)"
                        >
                            <el-option
                                v-for="item in tmFilingList"
                                :key="item.id"
                                :label="item.accountName"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </p> -->
                    <!-- <p>
                        <span v-if="btnP.channel">报备渠道</span>
                        <el-select
                            v-if="btnP.channel"
                            v-model="search.channelManageId"
                            size="small"
                            style="width: 200px"
                            placeholder="全部"
                            clearable
                            @change="(pagesize = 20), (currentPage = 1), getTableData(isStsts)"
                        >
                            <el-option
                                v-for="item in channeManagelListData"
                                :key="item.id"
                                :label="item.channelName"
                                :value="item.id"
                            ></el-option>
                        </el-select>
                    </p> -->
                    <p class="slee">
                        <el-select
                            v-model="search.timeType"
                            size="small"
                            style="
                                width: 100px;
                                margin: 0 13px;
                                font-weight: 600;
                            "
                            placeholder="请选择"
                            @change="search.inpTime = []"
                        >
                            <!-- <el-option label="试用到期" :value="1"></el-option> -->
                            <el-option label="报备时间" :value="2"></el-option>
                            <el-option label="回收时间" :value="3"></el-option>
                            <!-- <el-option label="创建时间" :value="4"></el-option> -->
                        </el-select>
                        <el-radio-group v-model="search.btnTime" size="small">
                            <el-radio-button
                                label="今日"
                                @click.native.prevent="clickBtnTime('今日')"
                            ></el-radio-button>
                            <el-radio-button
                                label="近7天"
                                @click.native.prevent="clickBtnTime('近7天')"
                            ></el-radio-button>
                            <el-radio-button
                                label="本月"
                                @click.native.prevent="clickBtnTime('本月')"
                            ></el-radio-button>
                        </el-radio-group>
                        <el-date-picker
                            size="small"
                            v-model="search.inpTime"
                            type="daterange"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            style="width: 240px; margin-left: 19px"
                            clearable
                            @change="expireTime"
                        >
                        </el-date-picker>
                    </p>
                    <p>
                        <span>查询</span>
                        <el-input
                            size="small"
                            style="width: 215px"
                            placeholder="请输入报备名称/客户名称/域名"
                            v-model.trim="search.nameVal"
                            clearable
                        >
                        </el-input>
                        <el-button
                            @click="onSenior()"
                            type="primary"
                            size="small"
                            icon="el-icon-search"
                            style="border-color: #2370eb"
                        ></el-button>
                    </p>
                </div>

                <p style="width: 200px">
                    <el-button
                        type="primary"
                        style="
                            margin-right: 16px;
                            border-color: #2370eb;
                            background: #2370eb;
                        "
                        size="small"
                        @click="twoSearch()"
                        >查询</el-button
                    >
                    <el-button
                        @click="searchEmpty"
                        type="primary"
                        style="
                            margin: 0;
                            background: none;
                            border-color: #d9d9d9;
                            color: #333333;
                            margin-right: 12px;
                        "
                        icon="el-icon-refresh-left"
                        size="small"
                        >重置</el-button
                    >
                </p>
            </div>
            <div class="search-two">
                <p>
                    <span>商机</span>
                    <el-radio-group v-model="search.adminVal" size="small">
                        <el-radio-button
                            label="我的"
                            @click.native.prevent="clickBtnTime('我的')"
                        ></el-radio-button>
                        <el-radio-button
                            label="合作"
                            @click.native.prevent="clickBtnTime('合作')"
                        ></el-radio-button>
                        <el-radio-button
                            label="配置人员"
                            @click.native.prevent="clickBtnTime('配置人员')"
                        ></el-radio-button>
                    </el-radio-group>
                </p>
            </div>
        </div>
        <div class="table-title">
            <div class="status_btn">
                <span
                    @click="(pagesize = 20), (currentPage = 1), getTableData(3)"
                    :class="isStsts == 3 ? 'isStatusColor' : ''"
                    >已报备</span
                >
                <span
                    @click="
                        (pagesize = 20),
                            (currentPage = 1),
                            getTableData(8),
                            (operNub = 0)
                    "
                    :class="isStsts == 8 ? 'isStatusColor' : ''"
                >
                    申请中
                </span>
                <span
                    @click="
                        (pagesize = 20), (currentPage = 1), getTableData(18)
                    "
                    :class="isStsts == 18 ? 'isStatusColor' : ''"
                    >已成交</span
                >
                <span
                    @click="(pagesize = 20), (currentPage = 1), getTableData(7)"
                    :class="isStsts == 7 ? 'isStatusColor' : ''"
                    >已失效</span
                >
            </div>
            <p class="Reporting">
                <span
                    style="font-size: 12px; margin-right: 8px; color: #666666"
                >
                    <i class="el-icon-warning-outline" style="color: #f5a623">
                    </i>
                    最大报备名额：
                    <span style="color: #d0021b">{{
                        account.maxReportNumber
                    }}</span>
                    &nbsp;&nbsp;已报备：
                    <span style="color: #2370eb">
                        {{ account.consumedNumber }}</span
                    >
                </span>

                <button
                    class="add"
                    @click="onReporting(1)"
                    v-show="btnP.add"
                    style="cursor: pointer; margin-right: 8px"
                >
                    <i class="iconfont icon-faqibaobei"></i> 报备
                </button>
            </p>
        </div>
        <el-table
            class="table-box"
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column prop="" width="10"> </el-table-column>
            <el-table-column label="商机ID" width="100" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{ scope.row.chanceNo || '- -' }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="报备名称" width="239" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-button
                        v-if="btnP.details"
                        type="text"
                        style="color: #2370eb"
                        @click="onInformation(scope.row)"
                        >{{ scope.row.reportName }}</el-button
                    >
                    <span v-else>{{ scope.row.reportName }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="域名" width="145">
                <template slot-scope="scope">
                    <div>
                        <el-tooltip effect="dark" placement="bottom">
                            <div
                                v-if="
                                    scope.row.reportDomain &&
                                    scope.row.reportDomain
                                "
                            >
                                {{ scope.row.reportDomain }}
                            </div>
                            <div slot="content" class="item">
                                <span
                                    style="
                                        display: inline-block;
                                        width: 150px;
                                        text-align: left;
                                    "
                                >
                                    {{ scope.row.reportDomain }}
                                </span>
                                <p
                                    v-for="item in scope.row.chanceDomainBOList"
                                    :key="item.id"
                                >
                                    <span
                                        style="
                                            display: inline-block;
                                            width: 150px;
                                            text-align: left;
                                        "
                                    >
                                        {{ item.domain }}
                                    </span>
                                </p>
                            </div>
                        </el-tooltip>
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="报备时间" show-overflow-tooltip width="150">
                <template slot-scope="scope">
                    <span v-if="scope.row.reportStartTime">{{
                        scope.row.reportStartTime
                    }}</span>
                    <span v-else>- -</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="回收时间" show-overflow-tooltip width="150">
                <template slot-scope="scope">
                    <span v-if="scope.row.reportExpireTime">{{
                        scope.row.reportExpireTime
                    }}</span>
                    <span v-else>- -</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>

            <el-table-column label="状态" show-overflow-tooltip width="120">
                <template slot-scope="scope">
                    <span>
                        {{
                            $MailStatus.reportStatus(scope.row.reportStatus)
                        }}</span
                    >
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>

            <el-table-column label="操作" fixed="right" width="180">
                <template slot-scope="scope">
                    <div
                        v-show="
                            scope.row.reportStatus != 8 &&
                            scope.row.reportStatus != 9 &&
                            scope.row.reportStatus != 11 &&
                            scope.row.reportStatus != 12 &&
                            scope.row.reportStatus != 13 &&
                            scope.row.reportStatus != 14 &&
                            scope.row.adminId == adminId
                        "
                    >
                        <el-button
                            style="color: #2370eb"
                            type="text"
                            v-if="
                                btnP.add &&
                                (scope.row.reportStatus == 6 ||
                                    scope.row.reportStatus == 7 ||
                                    scope.row.reportStatus == 10)
                            "
                            @click="onApply(scope.row)"
                            >申请报备</el-button
                        >
                        <el-button
                            style="color: #2370eb"
                            type="text"
                            @click="onEdit(scope.row), (isEdit = 2)"
                            v-if="
                                btnP.again &&
                                (scope.row.reportStatus == 17 ||
                                    scope.row.reportStatus == 19)
                            "
                            >重新报备</el-button
                        >

                        <el-button
                            style="color: #2370eb"
                            v-if="
                                btnP.reportDelay && scope.row.reportStatus == 3
                            "
                            type="text"
                            @click="extendReport(scope.row)"
                            >报备延期</el-button
                        >
                        <el-button
                            style="color: #2370eb"
                            v-if="btnP.giveup && scope.row.reportStatus == 3"
                            @click="giveReport(scope.row)"
                            type="text"
                            >放弃报备</el-button
                        >
                    </div>
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
        <div class="page-box">
            <el-pagination
                v-if="isPage"
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
        <!-- 编辑 -->
        <el-drawer
            :visible.sync="drawerEdit"
            :direction="direction"
            :before-close="handleClose"
            size="500px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    {{ '重新报备' }}
                </div>
            </template>
            <Edit @close="handleClose" ref="edit" />
        </el-drawer>
        <!-- 报备 -->
        <el-drawer
            :visible.sync="drawerReport"
            :direction="direction"
            :before-close="handleClose"
            size="520px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    报备申请
                </div>
            </template>
            <Reporting @close="handleClose" ref="report" />
        </el-drawer>
        <!-- 报备延期 -->
        <el-dialog
            :visible.sync="drawerReportDelay"
            :direction="direction"
            :before-close="handleClose"
            width="560px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    报备延期
                </div>
            </template>
            <ReportingDelay @close="handleClose" ref="reportDelay" />
        </el-dialog>

        <!-- 弹窗 -->
        <el-dialog
            :visible.sync="dialog"
            width="560px"
            :before-close="handleClose"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    {{ dialogTitle }}
                </div>
            </template>
            <div class="content">
                <p v-html="dialogContent"></p>
            </div>
            <div class="but">
                <el-button
                    round
                    type="primary"
                    size="mini"
                    style="
                        background: none;
                        border-color: #d5d6df;
                        color: #666666;
                        margin-left: auto;
                    "
                    @click="handleClose"
                    >取消</el-button
                >
                <el-button
                    round
                    type="primary"
                    style="
                        background: #2370eb;
                        border-color: #2370eb;
                        color: #fff;
                        margin-right: 30px;
                    "
                    size="mini"
                    @click="onSubmit"
                    >确定</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {
    reportList,
    reportGet,
    reportUpdate,
    reportApplyAudit,
    accountGet,
} from '@/api/report/newReport';
import { getCustomerId } from '@/api/user/ae/customer';

import { nameGet } from '@/api/customer/customer.js';
import Edit from './report/edit.vue';
import Reporting from './report/reporting.vue';
import ReportingDelay from './delay/reportingDelay.vue';
import Trial from './trial/trial.vue';
import TrialDelay from './delay/trialDelay.vue';
import History from './history/history.vue';
import { channeManagelList } from '@/api/reportchannel/reportchannel';
export default {
    components: {
        Edit,
        Reporting,
        Trial,

        ReportingDelay,
        TrialDelay,
        History,
    },
    data() {
        return {
            search: {
                nameVal: '',
                timeType: 2,
                btnTime: '',
                inpTime: [],
                libraryCategoryVal: '',
                channelManageId: '',
            },
            isEdit: 0,
            tableData: [],
            pagesize: 20,
            total: 0,
            currentPage: 1,
            isStsts: 3,
            drawerEdit: false,
            drawerReport: false,
            drawerReportDelay: false,
            drawerTrial: false,
            drawerTrialDelay: false,
            drawerHistory: false,
            drawerResetTrial: false,
            drawerDetails: false,
            direction: 'rtl',
            dialog: false,
            dialogTitle: '',
            dialogContent: '',
            dialogType: '',
            row: '',
            btnP: {},
            isPage: false,
            pageData: {},
            operNub: 0,
            storeNub: 0,
            account: {},
            tmFilingList: [],
            channeManagelListData: [],
            userType: sessionStorage.getItem('userType'),
            adminId: sessionStorage.getItem('adminId'),
        };
    },
    mounted() {
        if (
            sessionStorage.getItem('pageData') &&
            JSON.parse(sessionStorage.getItem('pageData'))
        ) {
            this.currentPage = JSON.parse(
                sessionStorage.getItem('pageData')
            ).pageNum;
            this.pagesize = JSON.parse(
                sessionStorage.getItem('pageData')
            ).pageSize;
            this.isStsts = JSON.parse(
                sessionStorage.getItem('pageData')
            ).param.isStsts;
            this.search = JSON.parse(sessionStorage.getItem('search'));
            sessionStorage.removeItem('pageData');
            this.isPage = true;
        } else {
            this.isPage = true;
        }
        if (sessionStorage.getItem('operNub')) {
            this.operNub = JSON.parse(sessionStorage.getItem('operNub'));
        }
        if (sessionStorage.getItem('storeNub')) {
            this.storeNub = JSON.parse(sessionStorage.getItem('storeNub'));
        }
        if (sessionStorage.getItem('companyName')) {
            this.search.nameVal = sessionStorage.getItem('companyName');
            sessionStorage.removeItem('companyName');
        }
        this.getBtn();
    },
    methods: {
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
            }
            this.btnP = btn;
            // let obj = {
            //     param: {},
            //     pageNum: 0,
            //     pageSize: 0,
            // };
            // accountList(obj).then((res) => {
            //     this.tmFilingList = res.data.list;
            // });
            this.getTableData(this.isStsts);
            // if (btn.channel) {
            //     this.channeManagelList();
            // }
        },
        channeManagelList() {
            let data = {
                param: {},
                pageNum: 0,
                pageSize: 0,
            };

            channeManagelList(data).then((res) => {
                this.channeManagelListData = res.data.list;
            });
        },
        getAccount() {
            let obj = {
                param: {
                    productType: 5,
                    adminId: sessionStorage.getItem('adminId'),
                },
            };
            accountGet(obj).then((res) => {
                console.log(res);
                this.account = res.data;
            });
        },
        onSenior() {
            this.search.btnTime = '';
            this.search.inpTime = [];
            this.search.timeType = 2;
            this.currentPage = 1;
            this.pagesize = 20;
            this.getTableData(this.isStsts);
        },
        twoSearch() {
            this.currentPage = 1;
            this.pagesize = 20;
            this.getTableData(this.isStsts);
        },
        getTableData(i) {
            this.getAccount();
            this.isStsts = i;
            let data = {
                param: {
                    productType: 5,

                    name: this.search.nameVal,
                    isStsts: this.isStsts,
                },
                pageNum: this.currentPage,
                pageSize: this.pagesize,
            };
            if (!this.search.adminVal) {
                data.param.adminId = sessionStorage.getItem('adminId');
            } else if (this.search.adminVal == '我的') {
                data.param.myAdminId = sessionStorage.getItem('adminId');
            } else if (this.search.adminVal == '合作') {
                data.param.collaboratorAdminId =
                    sessionStorage.getItem('adminId');
            } else if (this.search.adminVal == '配置人员') {
                data.param.configAdminId = sessionStorage.getItem('adminId');
            }
            //  isStsts: this.isStsts,
            //         timeType: this.search.timeType,
            //         channelManageId: this.search.channelManageId,
            if (i > 0) {
                data.param.reportStatusList = [i];
                if (i == 3) {
                    data.param.reportStatusList = [3, 11, 12, 13, 14, 20, 21];
                }
                if (i == 7) {
                    data.param.reportStatusList = [17, 19];
                }
                if (i == 8) {
                    data.param.reportStatusList = [11, 12, 20, 21];
                }
            }
            // if (i < 0) {
            //     data.param.trialStatusList = [Math.abs(i)];
            // }
            // if (this.search.libraryCategoryVal != '') {
            //     data.param.libraryCategory = this.search.libraryCategoryVal;
            // }
            var startTime = '';
            var endTime = '';
            if (this.search.btnTime != '') {
                let date = new Date();
                this.search.inpTime = [];
                if (this.search.btnTime == '今日') {
                    endTime = this.$searchTime.getNextDate(date, 0);
                    startTime = this.$searchTime.getNextDate(date, 0);
                } else if (this.search.btnTime == '近7天') {
                    if (
                        this.search.timeType == 1 ||
                        this.search.timeType == 3
                    ) {
                        endTime = this.$searchTime.getNextDate(date, -6);
                        startTime = this.$searchTime.getNextDate(date, 0);
                    }
                    if (
                        this.search.timeType == 2 ||
                        this.search.timeType == 4
                    ) {
                        startTime = this.$searchTime.getNextDate(date, 7);
                        endTime = this.$searchTime.getNextDate(date, 0);
                    }
                } else if (this.search.btnTime == '本月') {
                    if (
                        this.search.timeType == 1 ||
                        this.search.timeType == 3
                    ) {
                        startTime = this.$searchTime.getStartDay();
                        endTime = this.$searchTime.DateUtil.getEndDayOfMonth();
                    }
                    if (
                        this.search.timeType == 2 ||
                        this.search.timeType == 4
                    ) {
                        endTime = this.$searchTime.getNextDate(date, 0);
                        startTime = this.$searchTime.getStartDay();
                    }
                }
            }
            if (this.search.inpTime && this.search.inpTime.length > 0) {
                startTime = this.search.inpTime[0];
                endTime = this.search.inpTime[1];
            }
            // if (this.search.timeType == 1) {
            //     data.param.startTrialExpireDate = startTime;
            //     data.param.endTrialExpireDate = endTime;
            // } else
            if (this.search.timeType == 2) {
                data.param.startReportStartDate = startTime;
                data.param.endReportStartDate = endTime;
            } else if (this.search.timeType == 3) {
                data.param.startReportExpireDate = startTime;
                data.param.endReportExpireDate = endTime;
            }
            //  else if (this.search.timeType == 4) {
            //     data.param.startCreateDate = startTime;
            //     data.param.endCreateDate = endTime;
            // }

            this.pageData = data;
            reportList(data).then((res) => {
                this.total = res.data.total;
                this.tableData = res.data.list;
            });
        },

        // 时间点击
        clickBtnTime(e) {
            e === this.search.adminVal
                ? (this.search.adminVal = '')
                : (this.search.adminVal = e);
            this.currentPage = 1;
            this.pagesize = 20;
            this.getTableData(this.isStsts);
        },

        // 时间输入框变化
        expireTime() {
            this.search.nameVal = '';
            if (this.search.inpTime && this.search.inpTime.length > 0) {
                this.search.btnTime = '';
            }
            this.currentPage = 1;
            this.pagesize = 20;
            this.getTableData(this.isStsts);
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getTableData(this.isStsts);
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getTableData(this.isStsts);
        },
        // 报备
        onReporting(i) {
            this.drawerReport = true;
            setTimeout(() => {
                this.$refs.report.getData();
                this.$refs.report.getisType(i);
                this.$refs.report.accountList();
            }, 50);
        },
        // 延长报备
        extendReport(row) {
            this.row = row;

            if (
                row.chanceFrequencyBOList.length < 1 ||
                ((this.userType == 8 ||
                    this.userType == 9 ||
                    this.userType == 10) &&
                    row.chanceFrequencyBOList.length < 2)
            ) {
                this.dialogType = 11;
                this.dialog = true;
                this.dialogTitle = '报备延期';
                this.dialogContent =
                    '<sapn><i class="iconfont icon-shangchuanshibaixian-copy"></i></span>' +
                    '<br/>此次报备延期无需审批，报备时长将延期45天，是否确认？';
            } else {
                this.drawerReportDelay = true;
                let data = {
                    param: {
                        id: row.id,
                    },
                };
                reportGet(data).then((res) => {
                    this.$refs.reportDelay.getData(res.data);
                });
            }
        },
        // 申请报备
        async onApply(row) {
            this.row = row;
            if (row.nicheCategory == 1) {
                let res1 = await this.nameGet(row.companyName);
                let res2 = await this.getCustomerId();
                if (
                    res2.data.enterpriseType == 1 &&
                    res1.data.aiChaStatus != 1 &&
                    this.row.areaType == 1
                ) {
                    return this.$message.error(
                        '客户名称有误，无法对客户进行报备'
                    );
                }
            }
            let obj = {
                param: {
                    productType: 5,
                    adminId: sessionStorage.getItem('adminId'),
                },
            };
            accountGet(obj).then((res) => {
                if (res.data.maxReportNumber - res.data.consumedNumber <= 0) {
                    return this.$message.error(
                        '报备的名额大于分配的最大名额了'
                    );
                }
                this.account = res.data;
                this.dialogType = 8;
                this.dialog = true;
                this.dialogTitle = '申请报备';
                this.dialogContent =
                    '<sapn><i class="iconfont icon-shangchuanshibaixian-copy"></i></span>' +
                    '<br />即将提交报备申请，需要业务操作协作处理；<br />提交后无法编辑报备信息，是否确认？';
            });
        },
        nameGet(e) {
            let data = {
                param: {
                    companyName: e,
                },
            };
            return nameGet(data);
        },
        getCustomerId() {
            let data = {
                param: {
                    id: this.row.customerId,
                },
            };
            return getCustomerId(data);
        },
        // 放弃报备
        giveReport(row) {
            this.row = row;
            this.dialogType = 17;
            this.dialog = true;
            this.dialogTitle = '放弃报备';
            this.dialogContent =
                '<sapn><i class="iconfont icon-shangchuanshibaixian-copy"></i></span>' +
                '<br />即将放弃所选报备；成功放弃报备后，在停报时间内无法对该客户<br />再次申请报备，是否确认？';
        },
        onSubmit() {
            if (this.dialogType == 11) {
                let data = {
                    param: {
                        chanceDO: {
                            id: this.row.id,
                            reportStatus: 3,
                        },
                    },
                };
                reportApplyAudit(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('操作成功');
                        this.handleClose();
                    }
                });
            } else {
                let data = {
                    param: {
                        id: this.row.id,
                    },
                };
                if (this.dialogType > 0) {
                    data.param.reportStatus = this.dialogType;
                }
                if (this.dialogType < 0) {
                    data.param.trialStatus = Math.abs(this.dialogType);
                }

                reportUpdate(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('操作成功');
                        if (this.dialogType == 8) {
                            this.handleClose(1);
                        } else {
                            this.handleClose();
                        }
                    }
                });
            }
        },

        // 报备信息
        onInformation(row) {
            this.$router.push({
                path: '/tmDetail',
                query: {
                    id: row.id,
                    collaborator: this.btnP.collaborator || false,
                },
            });
            sessionStorage.setItem('pageData', JSON.stringify(this.pageData));
            sessionStorage.setItem('search', JSON.stringify(this.search));
        },
        // 编辑
        onEdit(row) {
            let data = {
                param: {
                    id: row.id,
                },
            };
            reportGet(data).then((res) => {
                let newDateTime = new Date().getTime();
                let giveUpReportTime = new Date(
                    res.data.giveUpReportTime
                ).getTime();
                // 5天隔离
                if (newDateTime - giveUpReportTime < 120000) {
                    return this.$message.error(
                        '当前客户仍在停报时间内，报备失败'
                    );
                }
                this.drawerEdit = true;
                setTimeout(() => {
                    this.$refs.edit.echoData(res.data);
                }, 0);
            });
        },

        handleClose(i) {
            this.dialog = false;
            this.drawerReport = false;
            this.drawerReportDelay = false;
            this.drawerTrial = false;
            this.drawerTrialDelay = false;
            this.drawerEdit = false;
            this.drawerHistory = false;
            this.drawerDetails = false;
            this.drawerResetTrial = false;

            if (i == 1) {
                if (this.isStsts != 8) {
                    this.operNub += 1;
                    sessionStorage.setItem(
                        'operNub',
                        JSON.stringify({ operNub: this.operNub })
                    );
                }
            }
            if (i == 2) {
                if (this.isStsts != 6) {
                    this.storeNub += 1;
                    sessionStorage.setItem(
                        'storeNub',
                        JSON.stringify({ storeNub: this.storeNub })
                    );
                }
            }

            this.getTableData(this.isStsts);
        },
        onDetails(row) {
            sessionStorage.setItem(
                'operNub',
                JSON.stringify({ operNub: this.operNub })
            );
            sessionStorage.setItem(
                'storeNub',
                JSON.stringify({ storeNub: this.storeNub })
            );
            let data = {
                param: {
                    id: row.customerId,
                },
            };
            getCustomerId(data).then((res) => {
                let btn = JSON.parse(sessionStorage.getItem('isButArr'));
                let type = false;
                for (let i in btn) {
                    if (btn[i].route == 'upData') {
                        type = true;
                        break;
                    }
                }
                if (type) {
                    this.$router.push({
                        path: '/customerdetails',
                        query: {
                            type: type,
                            isTab: 1,
                        },
                    });
                } else {
                    this.$router.push({
                        path: '/customerdetails',
                        query: {
                            isTab: 1,
                        },
                    });
                }
                this.$formDetails.commit(
                    'detailsData',
                    JSON.stringify(res.data)
                );
                this.$choiceLabel.commit('getType', '2');
                sessionStorage.setItem(
                    'pageData',
                    JSON.stringify(this.pageData)
                );
                sessionStorage.setItem('search', JSON.stringify(this.search));
            });
        },

        // 重置
        searchEmpty() {
            for (let i in this.search) {
                this.search[i] = '';
            }
            this.search.timeType = 2;
            this.search.inpTime = [];
            this.getTableData(3);
        },
        // 域名处理
        returnDomain(row) {},
    },
    beforeDestroy() {
        sessionStorage.removeItem('operNub');
        sessionStorage.removeItem('storeNub');
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.customer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .search-box {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 16px;
        span {
            font-size: 12px;
            font-weight: 600;
            color: #333333;
            margin: 0 16px;
        }
        .search-one {
            padding: 10px 0 0 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .left {
                display: flex;
                flex-wrap: wrap;
                p {
                    margin-bottom: 12px;
                }
                button {
                    background: #2370eb;
                    border-radius: 2px;
                    margin-right: 16px;
                }
            }
        }
    }
    .table-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 16px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        .status_btn {
            span {
                display: inline-block;
                border: 1px solid #f0f0f0;
                border-radius: 13px;
                padding: 4px 12px;
                font-size: 13px;
                color: #666666;
                margin-right: 12px;
                cursor: pointer;
                position: relative;
            }
            .isStatusColor {
                background: #e9f2ff;
                color: #2370eb;
                border-color: #e9f2ff;
            }
            .toast_gif {
                position: absolute;
                font-size: 12px;
                width: 16px;
                height: 16px;
                border-radius: 100px;
                color: #fff;
                text-align: center;
                line-height: 16px;
                font-weight: 560;
                right: -5px;
                top: -5px;
            }
        }

        .add {
            border-color: #2370eb;
            color: #fff;
            padding: 0 15px 0 15px;
            background: #2370eb;
            border: 1px solid #2370eb;
            border-radius: 2px;
            height: 24px;
            .icon-faqibaobei {
                font-size: 12px;
                margin-right: 8px;
            }
        }
        p {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
        }
    }
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.content {
    height: 200px;
    p {
        text-align: center;
        margin-top: 50px;
        font-size: 14px;
        color: #333;
        line-height: 30px;
        font-weight: 560;
        /deep/ .iconfont {
            color: #f5a623;
            font-size: 50px;
        }
    }
}
.search-two {
    padding-bottom: 12px;
}
.but {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
        width: 72px;
    }
}
.Reporting {
    text-align: right;
}
.table-box {
    width: 100%;
    height: 100%;
    .icon-ziyuanxhdpi {
        color: #2370eb;
        cursor: pointer;
    }
}
.slee {
    /deep/ .el-select .el-input__inner {
        font-weight: 600;
        color: #333;
    }
}
</style>
